import styled from '@emotion/styled';

const Container = styled('div')`
  position: absolute;
  display: flex;
  z-index: 999;
  background: white;
  padding: 16px;
  margin: 16px;
  box-shadow: 3px 3px 4px rgba(165, 162, 162, 0.25);
  border-radius: 12px;
`;

export default Container;
