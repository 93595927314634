import { makeAutoObservable, toJS } from 'mobx';
import { User } from '../types';

type SetField = 'sideBarOpen' | 'hideMenuButterSidebar';
class Settings {
  private me: User | null = null;
  sideBarOpen: boolean = true;
  hideMenuButterSidebar: boolean = false;

  constructor() {
    makeAutoObservable(this);
    this.init();
  }

  private init() {
    const settings = JSON.parse(localStorage.getItem('settings') ?? '{}');
    Object.keys(settings).forEach((key: string) => {
      this[key as SetField] = settings[key];
    });
  }

  setField(field: SetField, value: any) {
    const settings = JSON.parse(localStorage.getItem('settings') ?? '{}');
    localStorage.setItem(
      'settings',
      JSON.stringify({
        ...settings,
        [field]: value,
      }),
    );
    this[field] = value;
  }

  setMe(user: User) {
    this.me = user;
  }

  getMe() {
    return toJS(this.me);
  }
}

const settings = new Settings();

export default settings;
