import clsx from "clsx";
import React from "react";
type Props = {
  isOn?: boolean;
  isLow?: boolean;
  className?: string;
  type?: string;
};
export const PumpIcon = ({
  className,
  type,
  isLow = false,
  isOn = false,
}: Props) => {
  let fill = "none";
  let lineFill = "#F0F4F5";
  switch (type) {
    case "primary":
      fill = "#9CC5DD";
      break;
    default:
      fill = "#F0F4F5";
  }

  if (isOn) {
    lineFill = "#4AAF48";
  }

  if (isLow) {
    lineFill = "#ed6c02";
  }

  return (
    <svg
      className={className}
      width="106"
      height="104"
      viewBox="0 0 106 104"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 50C0 22.3858 22.3858 0 50 0H106V20H95.5V20C102.294 28.4117 106 38.898 106 49.7108V54C106 81.6142 83.6142 104 56 104H0V84H10L7.60009 80.1601C2.63347 72.2136 0 63.0312 0 53.6602V50Z"
        fill={fill}
      />
      <circle cx="53" cy="52" r="33" fill="white" />

      <circle cx="53" cy="52" r="10" fill={lineFill} />
      <line
        className={clsx({
          "infinite-rotation": false,
        })}
        x1="35"
        y1="35"
        x2="70"
        y2="70"
        stroke={lineFill}
        strokeWidth="9"
      />
    </svg>
  );
};
