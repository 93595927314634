import React from 'react';
import {
  DeviceEmergencyCode,
  deviceEmergencyCodeEnum,
} from '../../../lib/enums';
import { someIncludes } from '../../../lib/helpers';
import { GreenPointIcon, ErrorIcon, RepairIcon } from './icons';

type Props = {
  popups: {
    name: string;
    emergencyCode: DeviceEmergencyCode[];
    onRepair: boolean;
  }[];
};
export default ({ popups }: Props) => {
  return popups?.length === 1 ? (
    <div className="marker-kns-vns">
      <div className="icon">
        {popups[0].onRepair ? (
          <RepairIcon />
        ) : someIncludes(popups[0].emergencyCode, [
            deviceEmergencyCodeEnum.nonEmergency,
          ]) ? (
          <GreenPointIcon />
        ) : (
          <ErrorIcon />
        )}
      </div>
      <div className="text">{popups[0].name}</div>
    </div>
  ) : (
    <div className="round-marker">{popups?.length}</div>
  );
};
