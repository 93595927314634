import React from "react";
type Props = {
  type?: string;
  className?: string;
};
export const LightningIcon = ({ type, className }: Props) => {
  let fill = "none";
  switch (type) {
    case "error":
      fill = "#F29100";
      break;
    default:
      fill = "#D9D9D9";
  }
  return (
    <svg
      className={className}
      width="22"
      height="34"
      viewBox="0 0 22 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.32171 33.4215L9.36067 16.1004L21.5631 16.1003L3.32171 33.4215Z"
        fill={fill}
      />
      <path
        d="M17.122 0.843446L12.1713 18.6164L4.84092e-05 19.6187L17.122 0.843446Z"
        fill={fill}
      />
    </svg>
  );
};
