import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, CardContent } from '@mui/material';
import { GridValueGetterParams } from '@mui/x-data-grid';

import { Card, Breadcrumbs, BaseDataGrid } from '../../components/ui';
import { Header } from '../../components/layouts';
import { useUsers } from '../../lib/hooks';

const route = '/admin/users';
const title = 'Пользователи';
const List = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState<number>();
  const { isLoading, data } = useUsers(page);
  const columns = [
    {
      field: 'id',
      headerName: '№',
      sortable: false,
    },
    {
      field: 'userName',
      width: 200,
      headerName: 'Имя пользователя',
      sortable: false,
    },
    {
      field: 'role',
      width: 200,
      headerName: 'Роль',
      sortable: false,
      valueGetter: ({ value }: GridValueGetterParams) => {
        return value?.name ?? '-';
      },
    },
  ];
  const hanleChangePage = (page: number) => {
    setPage(page);
  };
  return (
    <>
      <Header
        title={title}
        right={
          <Button onClick={() => navigate(`${route}/new`)}>Создать</Button>
        }
      />
      <Breadcrumbs crumbs={[{ label: 'Главная', to: '/' }, { label: title }]} />
      <Card>
        <CardContent>
          <BaseDataGrid
            style={{ height: 'calc(100vh - 235px)' }}
            loading={isLoading}
            columns={columns}
            rows={data?.data}
            page={page}
            hanleChangePage={hanleChangePage}
            rowCount={data?.totalCount}
            onRowClick={({ row }: any) => {
              navigate(`${route}/${row.id}`);
            }}
          />
        </CardContent>
      </Card>
    </>
  );
};
export default List;
