import React from "react";
type Props = {
  type?: string;
  className?: string;
};
export const AccidentIcon = ({ type, className }: Props) => {
  let fill = "none";
  switch (type) {
    case "error":
      fill = "#F29100";
      break;
    default:
      fill = "#D9D9D9";
  }
  return (
    <svg
      className={className}
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.8094 2.45592C14.9095 0.225102 18.0905 0.225107 19.1906 2.45593L32.1197 28.6731C33.1029 30.6668 31.652 33 29.4291 33H3.5709C1.34795 33 -0.102903 30.6668 0.880296 28.6731L13.8094 2.45592Z"
        fill={fill}
      />
      <path
        d="M15.6029 5.82177C15.9694 5.07748 17.0306 5.07748 17.3971 5.82177L28.3474 28.0582C28.6746 28.7227 28.191 29.5 27.4502 29.5H5.54975C4.80902 29.5 4.32539 28.7227 4.65263 28.0582L15.6029 5.82177Z"
        fill="white"
      />
      <path
        d="M17.3251 13.8028L17.0929 22.3432H14.9131L14.6749 13.8028H17.3251ZM16.003 26.1548C15.6099 26.1548 15.2724 26.0159 14.9905 25.738C14.7086 25.456 14.5696 25.1186 14.5736 24.7255C14.5696 24.3364 14.7086 24.0029 14.9905 23.7249C15.2724 23.447 15.6099 23.308 16.003 23.308C16.3802 23.308 16.7117 23.447 16.9976 23.7249C17.2834 24.0029 17.4284 24.3364 17.4323 24.7255C17.4284 24.9875 17.3589 25.2277 17.2239 25.4461C17.0929 25.6605 16.9202 25.8332 16.7057 25.9643C16.4913 26.0913 16.2571 26.1548 16.003 26.1548Z"
        fill={fill}
      />
    </svg>
  );
};
