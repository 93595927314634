import React, { FC } from "react";
import { Modal, Grid } from "@mui/material";
import { observer } from "mobx-react-lite";
import { dispatcher } from "lib/ducks";
import clsx from "clsx";
import {
  deviceEmergencyCodeEnum,
  pumpStatus,
  pumpStatuseEnum,
  facilityTypeEnum,
} from "lib/enums";
import { Kns } from "lib/types";
import {
  AccidentIcon,
  DoorIcon,
  EllipseIcon,
  HorizontLineIcon,
  LightningIcon,
  LineIcon,
  PumpIcon,
  SchemaArrowIcon,
} from "../../components/icons";
import {
  StyledBox,
  StyledFooterGrid,
  StyledGrid,
  PupmLabel,
  PupmStatus,
  PupmWarning,
  SchemaContainer,
  Round,
  LineContainer,
  SchemaContainerPercent,
  CheckLevel,
} from "./styled";

type Props = {
  open: boolean;
  onClose: () => void;
};

export const SchemaModal: FC<Props> = observer(({ open, onClose }) => {
  const { active } = dispatcher;
  if (!active) return null;
  if (active.type === facilityTypeEnum.vns) return null;
  const handleClose = () => {
    onClose();
  };

  // открыта дверь
  const isOpen = active?.reedSwitchInformation;
  // затопление
  const isRoomFlooding = active.emergencyCode.includes(
    deviceEmergencyCodeEnum.roomFlooding,
  );
  // нет питания
  const isPhaseControlError = active.emergencyCode.includes(
    deviceEmergencyCodeEnum.phaseControlError,
  );
  // кнопка аварии
  const isEmergencyButton = active.emergencyCode.includes(
    deviceEmergencyCodeEnum.emergencyButton,
  );
  const liquidLevel = (active as unknown as any)?.liquidLevel ?? 0;
  return (
    <Modal open={open} onClose={handleClose} keepMounted>
      <StyledBox>
        <Grid
          container
          flexDirection="column"
          justifyContent="space-between"
          sx={{ height: "100%" }}>
          <Grid container flexGrow={1}>
            <Grid
              container
              item
              flexDirection="column"
              justifyContent="space-between"
              xs={2}>
              {active.emergencyCode.includes(
                deviceEmergencyCodeEnum.upperSensorError,
              ) ? (
                <StyledGrid item type="warning">
                  Ошибка датчика
                  <CheckLevel type="warning">3</CheckLevel>
                </StyledGrid>
              ) : (
                <StyledGrid
                  item
                  type={liquidLevel > 98 ? "success" : undefined}>
                  Сработка уровня
                  <CheckLevel>3</CheckLevel>
                </StyledGrid>
              )}
              <StyledGrid item type={liquidLevel > 65 ? "success" : undefined}>
                Сработка уровня
                <CheckLevel type={liquidLevel > 65 ? "success" : undefined}>
                  2
                </CheckLevel>
              </StyledGrid>
              {active.emergencyCode.includes(
                deviceEmergencyCodeEnum.lowerSensorError,
              ) ? (
                <StyledGrid sx={{ mb: "100px" }} item type="warning">
                  Ошибка датчика
                  <CheckLevel type="warning">1</CheckLevel>
                </StyledGrid>
              ) : (
                <StyledGrid
                  sx={{ mb: "100px" }}
                  item
                  type={liquidLevel > 32 ? "success" : undefined}>
                  Сработка уровня
                  <CheckLevel type={liquidLevel > 32 ? "success" : undefined}>
                    1
                  </CheckLevel>
                </StyledGrid>
              )}
            </Grid>

            <Grid item xs={8}>
              <Grid container flexDirection="row" sx={{ height: "100%" }}>
                <Grid item xs={4}>
                  <SchemaContainer load={liquidLevel}>
                    <SchemaContainerPercent load={liquidLevel}>
                      {liquidLevel}%
                    </SchemaContainerPercent>
                  </SchemaContainer>
                </Grid>

                <Grid
                  container
                  item
                  xs={4}
                  alignItems="end"
                  justifyContent="center">
                  <Round deg={175}>
                    {/* <SchemaArrowIcon className="arrow" /> */}
                    <div className="background"></div>
                    <div className="value">
                      <div className="num">
                        {(active as Kns).pumpEfficiency ?? 0}
                      </div>
                      <div>
                        м<sup>3</sup>
                      </div>
                    </div>
                  </Round>
                </Grid>

                <Grid container item xs={4}>
                  <LineContainer
                    item
                    flexDirection="column"
                    alignItems="center">
                    {(active as Kns).pumpStatuses.length > 2 && (
                      <LineIcon
                        height={254.49}
                        className="line"
                        type={clsx({
                          primary:
                            (active as Kns).pumpStatuses[
                              (active as Kns).pumpStatuses.length - 3
                            ] === pumpStatuseEnum.on,
                        })}
                      />
                    )}
                    {(active as Kns).pumpStatuses.length > 1 && (
                      <LineIcon
                        height={137}
                        className="line"
                        type={clsx({
                          primary:
                            (active as Kns).pumpStatuses[
                              (active as Kns).pumpStatuses.length - 2
                            ] === pumpStatuseEnum.on,
                        })}
                      />
                    )}
                    {(active as Kns).pumpStatuses.length > 0 && (
                      <>
                        <HorizontLineIcon
                          className="horizont"
                          height={20}
                          type={clsx({
                            primary:
                              (active as Kns).pumpStatuses[
                                (active as Kns).pumpStatuses.length - 1
                              ] === pumpStatuseEnum.on,
                          })}
                          style={{ right: -58 }}
                        />
                        <HorizontLineIcon
                          type={clsx({
                            primary: (active as Kns).pumpStatuses.includes(
                              pumpStatuseEnum.on,
                            ),
                          })}
                          style={{ zIndex: 9 }}
                          className="horizont"
                          width={300}
                          height={20}
                        />
                      </>
                    )}
                  </LineContainer>
                  <Grid
                    container
                    item
                    xs
                    flexDirection="column"
                    alignItems="center">
                    {(active as Kns).pumpStatuses.map(
                      (status: pumpStatuseEnum, index: number) => {
                        const isWork = status === pumpStatuseEnum.on;
                        const isLow = active.emergencyCode.includes(
                          // @ts-ignore
                          deviceEmergencyCodeEnum[`engine${index + 1}Overload`],
                        );
                        return (
                          <Grid
                            sx={{ display: "flex" }}
                            item
                            alignItems="end"
                            flex="1">
                            <PumpIcon
                              isOn={isWork}
                              isLow={isLow}
                              type={clsx({
                                primary: isWork,
                              })}
                            />
                          </Grid>
                        );
                      },
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container item flexDirection="column" xs={2}>
              {(active as Kns).pumpStatuses.map(
                (status: pumpStatuseEnum, index: number) => {
                  return (
                    <Grid container item flexDirection="column" flex="1">
                      <PupmLabel>Насос {index + 1}</PupmLabel>
                      <PupmStatus status={status}>
                        {pumpStatus[status]}
                      </PupmStatus>
                      {index === 0 &&
                        active.emergencyCode.includes(
                          deviceEmergencyCodeEnum.engine1Overload,
                        ) && (
                          <PupmWarning>низкая производительность</PupmWarning>
                        )}
                      {index === 1 &&
                        active.emergencyCode.includes(
                          deviceEmergencyCodeEnum.engine2Overload,
                        ) && (
                          <PupmWarning>низкая производительность</PupmWarning>
                        )}
                      {index === 2 &&
                        active.emergencyCode.includes(
                          deviceEmergencyCodeEnum.engine3Overload,
                        ) && (
                          <PupmWarning>низкая производительность</PupmWarning>
                        )}
                    </Grid>
                  );
                },
              )}
            </Grid>
          </Grid>

          <Grid container sx={{ ml: 3, mt: 6 }} spacing={1}>
            <Grid container item xs={3}>
              <Grid item xs={3} sx={{ pt: 0.25 }}>
                <DoorIcon type={isOpen ? "error" : undefined} />
              </Grid>
              <StyledFooterGrid item xs={9} error={isOpen}>
                Дверь в КНС открыта
              </StyledFooterGrid>
            </Grid>
            <Grid container item xs={3}>
              <Grid item xs={3} sx={{ pt: 0.25 }}>
                <EllipseIcon type={isRoomFlooding ? "error" : undefined} />
              </Grid>
              <StyledFooterGrid item xs={9} error={isRoomFlooding}>
                Затопление помещения
              </StyledFooterGrid>
            </Grid>
            <Grid container item xs={3}>
              <Grid item xs={3} sx={{ pt: 0.25 }}>
                <LightningIcon
                  type={isPhaseControlError ? "error" : undefined}
                />
              </Grid>
              <StyledFooterGrid item xs={9} error={isPhaseControlError}>
                В КНС нет питания
              </StyledFooterGrid>
            </Grid>
            <Grid container item xs={3}>
              <Grid item xs={3} sx={{ pt: 0.4 }}>
                <AccidentIcon type={isEmergencyButton ? "error" : undefined} />
              </Grid>
              <StyledFooterGrid item xs={9} error={isEmergencyButton}>
                Кнопка аварии
              </StyledFooterGrid>
            </Grid>
          </Grid>
        </Grid>
      </StyledBox>
    </Modal>
  );
});
