import React, { useEffect, useState } from 'react';
import {
  TableContainer,
  TableHead,
  TableRow,
  Table,
  TableBody,
  TablePagination,
  SxProps,
  Theme,
  Skeleton,
} from '@mui/material';

import { getQueryParams, setQueryParams } from '../../../lib/helpers';
import { HeaderTableCell, BodyTableCell } from './styled';

type ColumnsProps<T> = {
  title: string;
  width?: number | undefined;
  field: string;
  render?: (field: any, record: T) => any;
};
type PaginationProps = {
  pageSize: number | undefined;
  totalCount: number;
  page: number | undefined;
  hanleChangePage: (page: number) => void;
  hanleChangePerPage: (pageSize: number) => void;
};
type Props<T> = {
  columns: ColumnsProps<T>[];
  data: T[];
  isLoading: boolean;
  pagination?: PaginationProps;
  tableContainerSx?: SxProps<Theme>;
};
const StyledTable = <T,>({
  columns,
  data,
  tableContainerSx,
  isLoading,
  pagination: {
    pageSize,
    totalCount,
    page,
    hanleChangePage,
    hanleChangePerPage,
  } = {
    pageSize: 10,
    page: 0,
    totalCount: 0,
    hanleChangePage: () => {},
    hanleChangePerPage: () => {},
  },
}: Props<T>) => {
  const [random, setRandom] = useState('');
  const onPageChange = (_: any, value: number) => {
    const page = value + 1;
    setQueryParams({ page });
    hanleChangePage(page);
  };
  const onRowsPerPageChange = (e: any) => {
    const pageSize = e?.target?.value ?? 20;
    setQueryParams({ pageSize });
    hanleChangePerPage(pageSize);
  };
  useEffect(() => {
    const { page, pageSize } = getQueryParams();
    if (Number.isInteger(Number(page))) {
      hanleChangePage(Number(page));
    } else {
      hanleChangePage(1);
    }
    if (Number.isInteger(Number(pageSize))) {
      hanleChangePerPage(Number(pageSize));
    } else {
      hanleChangePerPage(20);
    }
    setRandom(`${Math.floor(Math.random() * 100)}`);
  }, []);
  const currentPage = Number.isInteger(Number(page)) ? Number(page) - 1 : 0;
  return (
    <>
      <TableContainer sx={tableContainerSx} key={`table-container-${random}`}>
        <Table key={`table-${random}`} stickyHeader sx={{ width: '100%' }}>
          <TableHead key={`table-head-${random}`}>
            <TableRow key={`table-row-${random}`}>
              {columns.map(({ title, width }, idx: number) => {
                return (
                  <HeaderTableCell
                    key={`header-table-cell-${random}-${idx}`}
                    style={{ width }}>
                    {title}
                  </HeaderTableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading
              ? Array(pageSize ?? 20)
                  .fill(null)
                  .map((_, idx) => {
                    return (
                      <TableRow key={`row-skeleton-${random}-${idx}`}>
                        {columns.map((_, index) => {
                          return (
                            <BodyTableCell
                              key={`row-cell-skeleton-${random}-${index}`}
                              align="inherit"
                              vertical="top">
                              <Skeleton />
                            </BodyTableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })
              : data.map((row: any, idx: number) => {
                  return (
                    <TableRow
                      key={`row-data-${random}-${idx}-${row?.id ?? idx}`}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                      }}>
                      {columns.map(({ field, render }, index) => {
                        return (
                          <BodyTableCell
                            key={`row-cell-data-${random}-${idx}-${index}-${
                              row?.id ?? index
                            }`}
                            align="inherit"
                            vertical="top">
                            {render ? render(row[field], row) : row[field]}
                          </BodyTableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        key={`pagination-${random}`}
        sx={{ borderTop: 1, borderColor: 'customGrey.border' }}
        component="div"
        showFirstButton
        showLastButton
        labelRowsPerPage="Показывать по:"
        rowsPerPageOptions={[5, 10, 20, 50]}
        labelDisplayedRows={({ from, to, count }) => {
          return `${from}–${to} из ${count !== -1 ? count : `более чем ${to}`}`;
        }}
        count={totalCount}
        rowsPerPage={pageSize ?? 20}
        page={currentPage}
        SelectProps={{
          inputProps: {
            'aria-label': 'rows per page',
          },
        }}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
      />
    </>
  );
};
export default StyledTable;
