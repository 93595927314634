import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseOutlined from "@mui/icons-material/Close";

export const HeaderContainer = styled(Grid)(({ theme }) => {
  return {
    alignItems: "center",
    position: "relative",
    borderBottom: `1px solid ${theme.palette.primary.light}`,
    padding: "16px",
  };
});

export const HeaderGridIcon = styled(Grid)(() => {
  return {
    justifyContent: "center",
  };
});

export const HeaderGridClose = styled(Grid)(() => {
  return {
    display: "flex",
    position: "absolute",
    height: "100%",
    right: "16px",
    alignItems: "center",
  };
});

export const CloseOutlinedStyled = styled(CloseOutlined)(() => {
  return {
    fontSize: "20px",
    cursor: "pointer",
  };
});
