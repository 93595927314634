import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { Header } from '../../components/layouts';
import { Breadcrumbs, Card } from '../../components/ui';
import { useAddUpdateEmployee, useEmployee } from '../../lib/hooks';
import { Employee } from '../../lib/types';
import InnerForm from './components/InnerForm';

const route = '/admin/employee';
const Form = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const mutation = useAddUpdateEmployee();
  const role = useEmployee(Number(id));
  const handleSubmit = (employee: Employee) => {
    mutation.mutate(employee, {
      onSuccess: () => {
        navigate(`${route}`);
      },
    });
  };
  const handleCancel = () => {
    navigate(-1);
  };
  return (
    <>
      <Header title={id ? 'Редактирование сотрудника' : 'Добавление нового сотрудника'} />
      <Breadcrumbs
        crumbs={[
          { label: 'Главная', to: '/' },
          { label: 'Сотрудники', to: route },
          { label: 'Сотрудник' },
        ]}
      />
      <Card>
        <InnerForm
          isLoading={role.isLoading}
          handleSubmit={handleSubmit}
          handleCancel={handleCancel}
          employee={role?.data ? (role.data as unknown as Employee) : ({} as Employee)}
        />
      </Card>
    </>
  );
};
export default Form;
