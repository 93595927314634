import React from 'react';
type Props = {
  type?: string;
  className?: string;
};
export default ({ type, className }: Props) => {
  let fill = 'none';
  switch (type) {
    case 'disabled':
      fill = '#CCD2E3';
      break;
    default:
      fill = '#6C98DA';
  }
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.1517 12.8774L6.01032 13.357L6.1517 12.8774ZM4.12243 10.8481L3.64284 10.9895L4.12243 10.8481ZM12.9776 10.8481L12.498 10.7068L12.9776 10.8481ZM10.9483 12.8774L10.807 12.3978L10.9483 12.8774ZM13.0184 7.60927L13.498 7.46789L13.0184 7.60927ZM6.73002 6.6748H10.37V5.6748H6.73002V6.6748ZM9.00503 12.4998H8.09502V13.4998H9.00503V12.4998ZM8.09502 12.4998C7.04098 12.4998 6.62173 12.4947 6.29309 12.3978L6.01032 13.357C6.5123 13.505 7.11172 13.4998 8.09502 13.4998V12.4998ZM3.5 8.90482C3.5 9.88812 3.49486 10.4875 3.64284 10.9895L4.60203 10.7068C4.50514 10.3781 4.5 9.95886 4.5 8.90482H3.5ZM6.29309 12.3978C5.47886 12.1578 4.84206 11.521 4.60203 10.7068L3.64284 10.9895C3.97888 12.1294 4.8704 13.021 6.01032 13.357L6.29309 12.3978ZM12.6 8.90482C12.6 9.95886 12.5949 10.3781 12.498 10.7068L13.4572 10.9895C13.6052 10.4875 13.6 9.88812 13.6 8.90482H12.6ZM9.00503 13.4998C9.98833 13.4998 10.5877 13.505 11.0897 13.357L10.807 12.3978C10.4783 12.4947 10.0591 12.4998 9.00503 12.4998V13.4998ZM12.498 10.7068C12.258 11.521 11.6212 12.1578 10.807 12.3978L11.0897 13.357C12.2296 13.021 13.1212 12.1294 13.4572 10.9895L12.498 10.7068ZM10.37 6.6748C11.0845 6.6748 11.334 6.67995 11.5242 6.73602L11.807 5.77683C11.4434 5.66966 11.0138 5.6748 10.37 5.6748V6.6748ZM13.6 8.90482C13.6 8.26107 13.6052 7.83143 13.498 7.46789L12.5388 7.75066C12.5949 7.94086 12.6 8.19034 12.6 8.90482H13.6ZM11.5242 6.73602C12.0127 6.88004 12.3948 7.26212 12.5388 7.75066L13.498 7.46789C13.258 6.65366 12.6212 6.01686 11.807 5.77683L11.5242 6.73602ZM6.73002 5.6748C6.08627 5.6748 5.65663 5.66966 5.29309 5.77683L5.57585 6.73602C5.76606 6.67995 6.01553 6.6748 6.73002 6.6748V5.6748ZM4.5 8.90482C4.5 8.19034 4.50514 7.94086 4.56122 7.75066L3.60203 7.46789C3.49486 7.83143 3.5 8.26107 3.5 8.90482H4.5ZM5.29309 5.77683C4.47886 6.01686 3.84206 6.65366 3.60203 7.46789L4.56122 7.75066C4.70524 7.26212 5.08731 6.88004 5.57585 6.73602L5.29309 5.77683Z"
        fill={fill}
      />
      <path
        d="M10.6135 6.11276C10.5793 6.38677 10.7737 6.63666 11.0477 6.67091C11.3217 6.70516 11.5716 6.51079 11.6058 6.23678L10.6135 6.11276ZM5.19146 3.08481C4.98272 3.26559 4.96005 3.58136 5.14083 3.7901C5.3216 3.99884 5.63737 4.02152 5.84612 3.84074L5.19146 3.08481ZM11.6058 6.23678L11.6499 5.88382L10.6577 5.75979L10.6135 6.11276L11.6058 6.23678ZM5.65689 2.68174L5.19146 3.08481L5.84612 3.84074L6.31154 3.43767L5.65689 2.68174ZM10.0617 2.40748C8.69923 1.49648 6.89585 1.60876 5.65689 2.68174L6.31154 3.43767C7.21003 2.65955 8.51783 2.57813 9.5059 3.23878L10.0617 2.40748ZM11.6499 5.88382C11.8207 4.51792 11.206 3.17259 10.0617 2.40748L9.5059 3.23878C10.3357 3.79363 10.7815 4.76925 10.6577 5.75979L11.6499 5.88382Z"
        fill={fill}
      />
      <circle cx="8.79984" cy="9.39994" r="1.19999" fill={fill} />
    </svg>
  );
};
