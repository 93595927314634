export enum facilityTypeEnum {
  kns = 0,
  vns = 1,
}

export type FacilityType = 0 | 1;

export const facilityType = {
  0: 'КНС',
  1: 'ВНС',
};
