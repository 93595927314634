import type { Credentials } from './../types';
import { apiUrl, fetchRequest } from './index';

export const login = async (credentials: Credentials): Promise<any> => {
  const response = await window.fetch(`${apiUrl}/auth/token`, {
    method: 'POST',
    body: JSON.stringify(credentials),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });

  if (response.ok) {
    return await response.json();
  } else {
    if (response.status === 400 || response.status === 401) {
      throw new Error('Invalid credentials');
    } else {
      throw new Error('Server error');
    }
  }
};

export const getEmployeeToken = async (employeeId: number): Promise<void> => {
  const token = await fetchRequest.post(`/auth/employeeToken/${employeeId}`);
  if (token) {
    return token;
  }
  throw new Error('Не удалось запросить токен для пользователя');
};

const authApi = { login, getEmployeeToken };

export default authApi;
