import React, { useState } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { isDate } from 'date-fns';
import { Modal, Box, Button, Grid, TextField, Skeleton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { DateTimePicker } from '@mui/x-date-pickers';
import { LoadingButton } from '@mui/lab';

import {
  Employee,
  Journal,
  JournalVehicle,
  Kns,
  Vns,
} from '../../../../lib/types';
import { EmployeeSelect, KnsVnsSelect } from '../../../../components/selects';
import { Title, ButtonContainer } from './elements';
import { useJournal } from '../../../../lib/hooks';
import { values } from 'lodash';

const transformDate = (_: any, originalValue: any) => {
  return isDate(originalValue) && !Date.parse(originalValue)
    ? null
    : originalValue;
};

const validationSchema = yup.object({
  id: yup.number().nullable(),
  date: yup
    .date()
    .nullable()
    .transform(transformDate)
    .required('Обязательное поле'),
  employeeId: yup.string().nullable().required('Обязательное поле'),
  objectId: yup.number().nullable().required('Обязательное поле'),
  reason: yup.string().nullable().required('Обязательное поле'),
  solution: yup.string().nullable().required('Обязательное поле'),
  vehicles: yup.array().of(
    yup.object().shape({
      journalId: yup.number().nullable(),
      licensePlate: yup
        .string()
        .nullable()
        .matches(
          /^([АВЕКМНОРСТУХ]{1}\d{3}[АВЕКМНОРСТУХ]{2}\d{2,3})|(\d{4}[АВЕКМНОРСТУХ]{2}\d{2,3})$/,
          'Не верный форат гос.номера',
        )
        .required('Обязательное поле'),
      startDate: yup
        .date()
        .nullable()
        .transform(transformDate)
        .required('Обязательное поле'),
      endDate: yup
        .date()
        .nullable()
        .transform(transformDate)
        .when('startDate', (startDate, schema) => {
          return startDate !== null
            ? schema.min(
                startDate,
                'Дата окончания не может быть раньше даты начала',
              )
            : schema;
        })
        .required('Обязательное поле'),
      // .when('journalId', (journalId, schema) => {
      //   return Number.isInteger(Number(journalId))
      //     ? schema.required('Обязательное поле')
      //     : schema;
      // }),
    }),
  ),
});
const style = {
  position: 'absolute' as 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  bgcolor: 'background.paper',
  borderRadius: 2,
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};
const emptyVehicle = { licensePlate: null, startDate: null, endDate: null };
const emptyJournal = {
  date: null,
  locationId: null,
  employeeId: null,
  reason: null,
  solution: null,
  vehicles: [{ ...emptyVehicle }],
};
type Props = {
  journalId: number | null;
  open: boolean;
  handleClose: () => void;
  isLoading: boolean;
  handleSubmit: (
    journal: Journal,
    deleteJournalVehicleIndexes: number[],
  ) => void;
  hiddenKnsVnsSelect?: boolean;
  initValue?: any;
};
export default ({
  open,
  handleClose,
  isLoading,
  handleSubmit,
  journalId,
  hiddenKnsVnsSelect,
  initValue = {},
}: Props) => {
  const [deleteJournalVehicleIndexes, setDelete] = useState<number[]>([]);
  const { isLoading: isLoadingJournal, data: journal } = useJournal(
    Number(journalId),
  );
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: (journal
      ? {
          ...journal,
          vehicles: journal.vehicles.length
            ? journal.vehicles
            : emptyJournal.vehicles,
        }
      : { ...emptyJournal, ...initValue }) as Journal,
    validationSchema,
    onSubmit: (journal) => {
      handleSubmit(journal, deleteJournalVehicleIndexes);
    },
  });
  const onSubmit = (e: React.FormEvent<HTMLFormElement> | undefined) => {
    formik.handleSubmit(e);
  };
  const deleteJournalVehicle = (vehicle: JournalVehicle, index: number) => {
    if (vehicle?.id) {
      setDelete((oldValue) => [...oldValue, Number(vehicle.id)]);
    }
    formik.setFieldValue('vehicles', [
      ...formik.values.vehicles.filter((_, ind) => ind !== index),
    ]);
  };
  const isSend = Boolean(formik.submitCount);
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description">
      <Box sx={{ ...style }}>
        <Title>
          <span className="title">
            {journalId ? 'Редактирование' : 'Новая запись'}
          </span>
          <CloseIcon onClick={handleClose} />
        </Title>
        <form onSubmit={onSubmit}>
          {isLoadingJournal ? (
            <SkeletonForm />
          ) : (
            <>
              <Grid sx={{ mt: 2 }} container spacing={2}>
                <Grid item md={3}>
                  <DateTimePicker
                    inputFormat="dd.MM.yyyy HH:mm"
                    mask="__.__.____ __:__"
                    renderInput={(props) => (
                      <TextField
                        fullWidth
                        size="small"
                        {...props}
                        error={
                          (formik.touched.date || isSend) &&
                          Boolean(formik.errors.date)
                        }
                        helperText={
                          (formik.touched.date || isSend) && formik.errors.date
                        }
                      />
                    )}
                    label="Время заявки"
                    value={formik.values?.date}
                    onChange={(date) => {
                      formik.setFieldValue('date', date);
                    }}
                  />
                </Grid>
                {!Boolean(hiddenKnsVnsSelect) && (
                  <Grid item md={3}>
                    <KnsVnsSelect
                      facilityType={formik.values.type}
                      onChange={(
                        id: number | number[] | undefined,
                        obj: Kns | Vns | undefined,
                      ) => {
                        formik.setFieldValue('objectId', id);
                        formik.setFieldValue('type', obj?.type ?? null);
                      }}
                      value={formik.values?.objectId ?? ''}
                      error={
                        (formik.touched.objectId || isSend) &&
                        Boolean(formik.errors.objectId)
                      }
                      helperText={
                        (formik.touched.objectId || isSend) &&
                        formik.errors.objectId
                      }
                    />
                  </Grid>
                )}
                <Grid item md={3}>
                  <EmployeeSelect
                    onChange={(
                      id: number | number[] | undefined,
                      employee: Employee | undefined,
                    ) => {
                      formik.setFieldValue('employeeId', id);
                    }}
                    value={formik.values?.employeeId ?? ''}
                    error={
                      (formik.touched.employeeId || isSend) &&
                      Boolean(formik.errors.employeeId)
                    }
                    helperText={
                      (formik.touched.employeeId || isSend) &&
                      formik.errors.employeeId
                    }
                  />
                </Grid>
                <Grid item md={3}>
                  <TextField
                    fullWidth
                    size="small"
                    id="reason"
                    name="reason"
                    label="Причина"
                    type="text"
                    InputProps={{
                      autoComplete: 'off',
                    }}
                    value={formik.values.reason || ''}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.reason && Boolean(formik.errors.reason)
                    }
                    helperText={formik.touched.reason && formik.errors.reason}
                  />
                </Grid>
              </Grid>

              <Grid
                sx={{ mt: 1, maxHeight: 280, overflow: 'auto' }}
                container
                spacing={2}>
                {formik.values.vehicles.map((vehicle, index) => {
                  let errors = {
                    licensePlate: {
                      touched: false,
                      error: null,
                    },
                    startDate: {
                      touched: false,
                      error: null,
                    },
                    endDate: {
                      touched: false,
                      error: null,
                    },
                  };
                  if (Array.isArray(formik.touched?.vehicles)) {
                    errors.licensePlate.touched = Boolean(
                      formik.touched.vehicles[index]?.licensePlate,
                    );
                    errors.startDate.touched = Boolean(
                      formik.touched.vehicles[index]?.startDate,
                    );
                    errors.endDate.touched = Boolean(
                      formik.touched.vehicles[index]?.endDate,
                    );
                  }
                  if (Array.isArray(formik.errors?.vehicles)) {
                    const veh: any = formik.errors.vehicles[index];
                    errors.licensePlate.error = veh?.licensePlate;
                    errors.startDate.error = veh?.startDate;
                    errors.endDate.error = veh?.endDate;
                  }
                  return (
                    <Grid container item md={12} spacing={2}>
                      <Grid item md={3}>
                        <TextField
                          fullWidth
                          size="small"
                          id={`vehicles[${index}].licensePlate`}
                          name={`vehicles[${index}].licensePlate`}
                          label="Транспорт"
                          type="text"
                          InputProps={{
                            autoComplete: 'off',
                          }}
                          value={vehicle?.licensePlate || ''}
                          onChange={formik.handleChange}
                          error={
                            errors.licensePlate.touched &&
                            Boolean(errors.licensePlate.error)
                          }
                          helperText={
                            errors.licensePlate.touched &&
                            errors.licensePlate.error
                          }
                        />
                      </Grid>
                      <Grid item md={3}>
                        <DateTimePicker
                          inputFormat="dd.MM.yyyy HH:mm"
                          mask="__.__.____ __:__"
                          renderInput={(props) => (
                            <TextField
                              fullWidth
                              size="small"
                              {...props}
                              error={
                                errors.startDate.touched &&
                                Boolean(errors.startDate.error)
                              }
                              helperText={
                                errors.startDate.touched &&
                                errors.startDate.error
                              }
                            />
                          )}
                          label="Дата начала"
                          value={vehicle?.startDate}
                          onChange={(date) => {
                            formik.setFieldValue(
                              `vehicles[${index}].startDate`,
                              date,
                            );
                          }}
                        />
                      </Grid>
                      <Grid item md={3}>
                        <DateTimePicker
                          inputFormat="dd.MM.yyyy HH:mm"
                          mask="__.__.____ __:__"
                          renderInput={(props) => (
                            <TextField
                              fullWidth
                              size="small"
                              {...props}
                              error={
                                errors.endDate.touched &&
                                Boolean(errors.endDate.error)
                              }
                              helperText={
                                errors.endDate.touched && errors.endDate.error
                              }
                            />
                          )}
                          label="Дата окончания"
                          value={vehicle?.endDate}
                          onChange={(date) => {
                            formik.setFieldValue(
                              `vehicles[${index}].endDate`,
                              date,
                            );
                          }}
                        />
                      </Grid>
                      <Grid item md={3}>
                        {index + 1 === formik.values.vehicles.length ? (
                          <Button
                            key={`add-${index}`}
                            onClick={() => {
                              formik.setFieldValue('vehicles', [
                                ...formik.values.vehicles,
                                { ...emptyVehicle },
                              ]);
                            }}>
                            + Добавить транспорт
                          </Button>
                        ) : (
                          <Button
                            key={`delete-${index}`}
                            color="error"
                            onClick={() => {
                              deleteJournalVehicle(vehicle, index);
                            }}>
                            Удалить
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>

              <Grid sx={{ mt: 1, mb: 1 }} container spacing={2}>
                <Grid item md={9}>
                  <TextField
                    fullWidth
                    id="outlined-multiline-static"
                    label="Меры устранения"
                    multiline
                    rows={4}
                    name="solution"
                    value={formik.values?.solution}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.solution && Boolean(formik.errors.solution)
                    }
                    helperText={
                      formik.touched.solution && formik.errors.solution
                    }
                  />
                </Grid>
              </Grid>
            </>
          )}

          <ButtonContainer>
            <LoadingButton
              loading={isLoading}
              disabled={isLoadingJournal}
              color="primary"
              variant="contained"
              type="submit">
              Cохранить
            </LoadingButton>
            <LoadingButton onClick={handleClose} loading={isLoading}>
              Отменить
            </LoadingButton>
          </ButtonContainer>
        </form>
      </Box>
    </Modal>
  );
};

const SkeletonForm = () => {
  return (
    <>
      <Grid sx={{ mt: 2 }} container spacing={2}>
        <Grid item md={3}>
          <Skeleton />
        </Grid>
        <Grid item md={3}>
          <Skeleton />
        </Grid>
        <Grid item md={3}>
          <Skeleton />
        </Grid>
        <Grid item md={3}>
          <Skeleton />
        </Grid>
      </Grid>

      <Grid
        sx={{ mt: 1, maxHeight: 280, overflow: 'auto' }}
        container
        spacing={2}>
        <Grid container item md={12} spacing={2}>
          <Grid item md={3}>
            <Skeleton />
          </Grid>
          <Grid item md={3}>
            <Skeleton />
          </Grid>
          <Grid item md={3}>
            <Skeleton />
          </Grid>
          <Grid item md={3}>
            <Skeleton />
          </Grid>
        </Grid>
      </Grid>

      <Grid sx={{ mt: 1, mb: 1 }} container spacing={2}>
        <Grid item md={9}>
          <Skeleton height="100" />
        </Grid>
      </Grid>
    </>
  );
};
