import { UseInfiniteQueryResult, useInfiniteQuery } from 'react-query';
import { journalApi } from '../../api';
import { FilterJournals } from './useFetch';

const useFetchInfinity = (
  filter?: FilterJournals,
  pageSize: number | undefined = 5,
): UseInfiniteQueryResult => {

  const res = useInfiniteQuery(
    ['fetchInfinityJournal', pageSize, filter],
    ({ pageParam = 1 }) => {
      return journalApi.fetch({ page: pageParam, pageSize, ...filter })
    },

    {
      enabled: Boolean(pageSize),
      getNextPageParam: ({ totalCount }, pages) => {
        const page = pages.length;
        if (page * pageSize > totalCount) {
          return undefined;
        } else {
          return page + 1;
        }
      },
    },
  );
  return res;
};

export default useFetchInfinity;
