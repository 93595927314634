import React from 'react';
import { TextFieldProps } from '@mui/material';
import { facilityType, facilityTypeEnum, FacilityType } from '../../lib/enums';
import { Kns, Vns } from '../../lib/types';
import { knsApi, vnsApi } from '../../lib/api';

import AutoCompleteSelect from './AutoCompleteSelect';

const getName = ({ location: { name }, deviceCode, type }: Kns | Vns) => {
  return `${name} тип - ${facilityType[type]}, код устройства: ${deviceCode}`;
};
type Props = {
  facilityType: FacilityType | null;
  onChange: (
    id: number | number[] | undefined,
    employee: Kns | Vns | undefined,
  ) => void;
} & Omit<TextFieldProps, 'onChange'>;
const Select = (props: Props) => {
  return (
    <>
      <AutoCompleteSelect
        id="objectId"
        name="objectId"
        label="Выберите КНС/ВНС"
        size="small"
        fullWidth
        fetch={async (params) => {
          const res = await Promise.all([
            knsApi.fetch(params),
            vnsApi.fetch(params),
          ]).then((data) => {
            const res: { data: (Kns | Vns)[]; totalCount: number } = {
              data: [],
              totalCount: 0,
            };
            if (Array.isArray(data)) {
              data.forEach(({ data, totalCount }) => {
                res.data = [...res.data, ...data];
                res.totalCount += totalCount;
              });
            }
            return res;
          });
          return {
            ...res,
            data:
              res?.data?.map((obj: Kns | Vns) => {
                return {
                  ...obj,
                  name: getName(obj),
                };
              }) ?? [],
          };
        }}
        fetchSingle={async (id: number) => {
          let res = null;
          switch (props.facilityType) {
            case facilityTypeEnum.kns:
              res = await knsApi
                .get(id)
                .then((kns: Kns) => ({ ...kns, name: getName(kns) }));
              break;
            case facilityTypeEnum.vns:
              res = await vnsApi
                .get(id)
                .then((vns: Vns) => ({ ...vns, name: getName(vns) }));
              break;
            default:
              return null;
          }
          return res;
        }}
        {...props}
      />
    </>
  );
};
export default Select;
