import React from "react";
type Props = {
  className?: string;
  type?: string;
  height: number;
  width?: number;
};
export const LineIcon = ({ className, height, type, width = 61 }: Props) => {
  let stroke = "none";
  let zIndex = 9;
  switch (type) {
    case "primary":
      stroke = "#9CC5DD";
      zIndex = 999;
      break;
    default:
      stroke = "#F0F4F5";
  }
  return (
    <svg
      className={className}
      style={{ zIndex }}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d={`M10.7261 ${height}L10.726 41.1754C10.7259 24.0905 24.549 10.2263 41.6338 10.1755L${width} 10.1209`}
        stroke={stroke}
        stroke-width="20"
      />
    </svg>
  );
};
