import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import { UseMutationResult } from 'react-query';
import { Button, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import PrintIcon from '@mui/icons-material/Print';

import {
  Employee,
  Journal,
  JournalVehicle,
  Kns,
  OrgUnit,
  User,
  Vns,
} from '../../../lib/types';
import {
  useAddUpdateJournal,
  useAddUpdateJournalVehicle,
  useDeleteJournalVehicle,
  useJournals,
  FilterJournals,
} from '../../../lib/hooks';
import { settings } from '../../../lib/ducks';
import { facilityTypeEnum } from '../../../lib/enums';
import {
  getQueryParams,
  localeDate,
  localeTime,
  setQueryParams,
} from '../../../lib/helpers';

import { drawerWidth } from '../../../components/layouts/Sidebar';
import { StyledTable } from '../../../components/ui/Table';

import Header from '../components/Header';
import HorizontList from '../components/HorizontList';

import ModalForm from './components/ModalForm';
import { StyledPaper, StyledDate } from './components/elements';

/**вынес в отдельную функцию, для переиспользования */
export const handleSubmitJornal = async (
  journal: Journal,
  deleteJournalVehicleIndexes: number[],
  mutationJournal: UseMutationResult,
  deleteJournalVehicle: UseMutationResult,
  mutationJournalVehicle: UseMutationResult,
  callback: () => void,
) => {
  let promises: any[] = [];
  let journalId: number = 0;
  if (journal?.id) {
    promises.push(mutationJournal.mutateAsync(journal));
    deleteJournalVehicleIndexes.forEach((id) => {
      promises.push(deleteJournalVehicle.mutateAsync(Number(id)));
    });
    journalId = journal.id;
  } else {
    const res = await mutationJournal.mutateAsync(journal);
    journalId = (res as Journal)?.id ?? 0;
  }
  journal.vehicles.forEach((veh) => {
    promises.push(
      mutationJournalVehicle.mutateAsync({
        ...veh,
        journalId,
      }),
    );
  });
  await Promise.all(promises).finally(callback);
};

const List = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [filter, setFilter] = useState<FilterJournals>();
  const [editId, setEditId] = useState<number | null>(null);
  const [page, setPage] = useState<number>();
  const [pageSize, setPageSize] = useState<number>();
  const mutationJournal = useAddUpdateJournal();
  const mutationJournalVehicle = useAddUpdateJournalVehicle();
  const deleteJournalVehicle = useDeleteJournalVehicle();
  const {
    isLoading,
    data: journals,
    refetch,
  } = useJournals(page, pageSize, filter);
  const { sideBarOpen } = settings;
  const columns = [
    {
      title: 'Время заявки',
      width: 100,
      field: 'date',
      render: (date: string, row: Journal) => {
        return (
          <span key={`date-${row?.id}`}>
            {localeTime(date)} <StyledDate>{localeDate(date)}</StyledDate>
          </span>
        );
      },
    },
    {
      title: 'Адрес',
      field: 'object',
      render: (object: Kns | Vns) => {
        return object?.location?.shortAddress ?? '-';
      },
    },
    {
      title: 'Выполнена',
      width: 100,
      field: 'vehicles',
      render: (vehicles: JournalVehicle[]) => {
        return (
          vehicles?.map((vehicle, index: number) => {
            return (
              <div key={`${vehicle?.id}-${index}`}>
                {localeTime(vehicle.startDate ?? '')} -{' '}
                {localeTime(vehicle.endDate ?? '')}
              </div>
            );
          }) ?? '-'
        );
      },
    },
    {
      title: 'Транспорт',
      field: 'vehicles',
      render: (vehicles: JournalVehicle[]) => {
        return (
          vehicles?.map((vehicle, index) => {
            return (
              <div key={`index-${vehicle?.id}`}>{vehicle.licensePlate}</div>
            );
          }) ?? '-'
        );
      },
    },
    { title: 'Причина', field: 'reason' },
    { title: 'Меры устранения', field: 'solution' },
    {
      title: 'Исполнитель',
      field: 'employee',
      render: (employee: Employee) => {
        return (
          employee &&
          `${employee?.firstName} ${employee?.middleName} ${employee?.lastName}`
        );
      },
    },
    {
      title: 'Диспетчер',
      field: 'user',
      render: (user: User) => {
        return user?.employee
          ? `${user.employee.firstName} ${user.employee.middleName} ${user.employee.lastName}`
          : user?.userName ?? '-';
      },
    },
    {
      title: '',
      field: 'id',
      render: (id: number) => {
        return (
          <IconButton
            color="primary"
            onClick={() => {
              setEditId(id);
              setOpen(true);
            }}>
            <EditIcon />
          </IconButton>
        );
      },
    },
  ];
  const handleSubmit = async (
    journal: Journal,
    deleteJournalVehicleIndexes: number[],
  ) => {
    handleSubmitJornal(
      journal,
      deleteJournalVehicleIndexes,
      mutationJournal as UseMutationResult,
      deleteJournalVehicle as UseMutationResult,
      mutationJournalVehicle as UseMutationResult,
      () => {
        setOpen(false);
        setEditId(null);
        refetch();
      },
    );
  };
  const hanleChangePage = (page: number) => {
    setPage(page);
  };
  const hanleChangePerPage = (pageSize: number) => {
    setPageSize(pageSize);
  };
  const handleChangeFilter = (field: string, value: any) => {
    setFilter((oldValue) => ({
      ...oldValue,
      [field]: value,
    }));
    setQueryParams({ [field]: value });
  };
  useEffect(() => {
    const { type, organizationUnitId } = getQueryParams();
    setFilter({ type, organizationUnitId });
  }, []);
  return (
    <>
      {open && (
        <ModalForm
          journalId={editId}
          isLoading={
            mutationJournal.isLoading || mutationJournalVehicle.isLoading
          }
          open={true}
          handleClose={() => {
            setOpen(false);
          }}
          handleSubmit={handleSubmit}
        />
      )}
      <Header />
      <StyledPaper
        style={{
          width: `calc(100% - 32px - ${sideBarOpen ? drawerWidth : 0}px)`,
          height: `calc(100% - 130px)`,
        }}>
        <HorizontList
          key="hor-list-org-unit"
          list={[
            {
              label: 'Все',
              isActive: filter?.organizationUnitId === undefined,
              onClick: () =>
                handleChangeFilter('organizationUnitId', undefined),
            },
            ...(settings
              .getMe()
              ?.role?.organizationUnits.map((orgUnit: OrgUnit) => {
                return {
                  label: orgUnit.name,
                  isActive: filter?.organizationUnitId === orgUnit.id,
                  onClick: () =>
                    handleChangeFilter('organizationUnitId', orgUnit.id),
                };
              }) ?? []),
          ]}
          right={
            <IconButton>
              <PrintIcon />
            </IconButton>
          }
        />
        <HorizontList
          key="hor-list-type"
          list={[
            {
              label: 'Все',
              isActive: filter?.type === undefined,
              onClick: () => {
                handleChangeFilter('type', undefined);
              },
            },
            {
              label: 'КНС',
              isActive: filter?.type === facilityTypeEnum.kns,
              onClick: () => {
                handleChangeFilter('type', facilityTypeEnum.kns);
              },
            },
            {
              label: 'ВНС',
              isActive: filter?.type === facilityTypeEnum.vns,
              onClick: () => {
                handleChangeFilter('type', facilityTypeEnum.vns);
              },
            },
          ]}
          right={
            <div style={{ width: '190px' }}>
              <Button
                onClick={() => {
                  setOpen(true);
                  setEditId(null);
                }}
                variant="outlined">
                {' '}
                + Добавить запись
              </Button>
            </div>
          }
        />
        <StyledTable
          key="journal-table"
          tableContainerSx={{
            minHeight: 'calc(100% - 200px)',
            maxHeight: 'calc(100% - 200px)',
          }}
          isLoading={isLoading}
          columns={columns}
          data={journals?.data ?? []}
          pagination={{
            pageSize,
            page,
            totalCount: journals?.totalCount ?? 0,
            hanleChangePage,
            hanleChangePerPage,
          }}
        />
      </StyledPaper>
      <MapContainer
        style={{ height: '100vh' }}
        center={[55.791278, 49.115734]}
        zoom={13}
        zoomControl={false}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
      </MapContainer>
    </>
  );
};
export default List;
