import { Grid, IconButton, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import { observer } from "mobx-react-lite";
import { drawerWidth } from "components/layouts/Sidebar";
import { settings } from "lib/ducks";

export const StyledIconBtn = styled(IconButton)(({ theme }) => {
  return {
    padding: 0,
  };
});

export const Title = styled(Grid)(() => {
  return {
    fontSize: 24,
    fontWeight: 500,
    lineHeight: "32px",
  };
});

export const GridMarginTop16 = styled(Grid)(() => {
  return {
    marginTop: "16px",
  };
});

export const Label = styled("div")<{ display?: string }>(
  ({ theme, display = "block" }) => {
    return {
      display,
      lineHeight: "16px",
      color: theme.palette.customGrey.main,
    };
  },
);

export const MarginLeftAuto = styled(Grid)(({ theme }) => {
  return {
    marginLeft: "auto",
  };
});

export const Container = observer(
  styled("div")(({ theme }) => {
    return {
      position: "absolute",
      zIndex: theme.zIndex.modal,
      marginTop: "94px",
      padding: theme.spacing(2),
      width: `calc(100% - 48px - ${settings.sideBarOpen ? drawerWidth : 0}px)`,
      pointerEvents: "none",
      fontSize: 14,
    };
  }),
);

export const KnsVnsListInfoContainer = styled(Paper)(() => {
  return {
    position: "relative",
    pointerEvents: "fill",
    borderRadius: 12,
  };
});

export const DivScroll = styled("div")<{ height: string }>(({ height }) => {
  return {
    overflow: "auto",
    height,
  };
});
