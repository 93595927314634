import { Grid } from '@mui/material';
import { Edit } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { GridMarginTop16 } from './common.elements';

export const JournalContainer = styled(Grid)(() => {
  return {
    padding: '16px',
  };
});

export const JournaBody = styled(GridMarginTop16)(({ theme }) => {
  return {
    background: theme.palette.primary.light,
    borderRadius: '4px',
    padding: '8px',
  };
});

export const JournalEditIcon = styled(Edit)(() => {
  return {
    fontSize: '16px',
  };
});
