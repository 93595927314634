import { Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledDate = styled('span')(({ theme }) => {
  return {
    color: theme.palette.customGrey.main,
  };
});

export const StyledPaper = styled(Paper)(({ theme }) => {
  return {
    position: 'absolute',
    zIndex: 999,
    margin: `110px ${theme.spacing(2)} 0px`,
  };
});

export const ButtonContainer = styled('div')(({ theme }) => {
  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    gap: theme.spacing(2),
  };
});

export const Title = styled('div')(({ theme }) => {
  return {
    display: 'flex',
    alignItems: 'center',
    '& .title': {
      flex: 1,
      fontWeight: 500,
      fontSize: '24px',
      lineHeight: '32px',
    },
  };
});
