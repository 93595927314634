import styled from '@emotion/styled';

const MarkerIconContainer = styled('div')`
  display: flex;
  text-align: center;
  padding: 8px 16px;
  cursor: pointer;
  &.active {
    background: #e5f3fb;
    border-radius: 12px;
  }
`;

export default MarkerIconContainer;
