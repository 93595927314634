import { useMutation } from 'react-query';
import { employeeApi } from '../../api';
import { Employee } from '../../types';

const useAddUpdate = () => {
  const mutation = useMutation((employee: Employee): any => {
    const data = {
      ...employee,
      position: Number(employee.position),
    };
    return employee?.id ? employeeApi.update(data) : employeeApi.add(data);
  });
  return mutation;
};

export default useAddUpdate;
