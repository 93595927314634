export enum deviceEmergencyCodeEnum {
  nonEmergency = 0,
  engine1Overload = 1,
  engine2Overload = 2,
  engine3Overload = 4,
  enginesLowEfficiency = 8,
  lowerSensorError = 16,
  upperSensorError = 32,
  pitOverflow = 64,
  roomFlooding = 128,
  emergencyButton = 256,
  phaseControlError = 512,
}

export type DeviceEmergencyCode =
  | 0
  | 1
  | 2
  | 4
  | 8
  | 16
  | 32
  | 64
  | 128
  | 256
  | 512;

export const deviceEmergencyCode = {
  0: "Безаварийная ситуация",
  1: "Низкая производительность насоса №1",
  2: "Низкая производительность насоса №2",
  4: "Низкая производительность насоса №3",
  8: "Низкая общая производительность насосов",
  16: "Ошибка нижнего датчика",
  32: "Ошибка верхнего датчика",
  64: "Переполнение ямы",
  128: "Затопление помещения",
  256: "Кнопка аварии",
  512: "Ошибка контроля фазы",
};
