import { useMutation } from 'react-query';
import { journalApi } from '../../api';
import { Journal } from '../../types';

const useAddUpdate = () => {
  const mutation = useMutation((journal: Journal): any => {
    return journal?.id
      ? journalApi.updatePut(journal)
      : journalApi.add(journal);
  });
  return mutation;
};

export default useAddUpdate;
