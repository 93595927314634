import { Dispatcher, DispatcherJournalsList } from '../pages/Dispatcher';
import { accessTypeEnum } from '../lib/enums';

export default [
  {
    component: Dispatcher,
    path: '/',
    access: [accessTypeEnum.admin, accessTypeEnum.read, accessTypeEnum.write],
  },
  {
    component: DispatcherJournalsList,
    path: '/dispatcher-journals',
    access: [accessTypeEnum.admin, accessTypeEnum.read, accessTypeEnum.write],
  },
];
