import { Grid } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import PauseIcon from '@mui/icons-material/Pause';
import { styled } from '@mui/material/styles';

export const KnsVnsListRowContainer = styled('div')(() => {
  return {
    height: 'calc(100vh - 270px)',
    overflow: 'auto',
  };
});

export const KnsVnsListCloseContainer = styled('div')(() => {
  return { position: 'absolute', top: 5, right: 5 };
});

export const KnsVnsListRow = styled(Grid)(({ theme }) => {
  return {
    padding: '16px 0px',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.primary.light}`,
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.primary.light,
    },
    '&.active': {
      background: theme.palette.primary.light,
    },
  };
});

export const ItemIcon = styled(Grid)(() => {
  return {
    justifyContent: 'center',
    maxWidth: 40,
    display: 'flex',
  };
});

export const CircleLetter = styled('div')`
  font-size: 10px;
  line-height: 12px;
  border-radius: 50%;
  background: #e5f3fb;
  border: 1px solid #6c98da;
  color: #6c98da;
  font-weight: 500;
  width: 16px;
  height: 16px;
  text-align: center;
`;

export const Text = styled(Grid)(({ theme }) => {
  return {
    fontSize: 14,
    lineHeight: '16px',
    '& .anticon': {
      color: theme.palette.primary.main,
    },
    '&.pause': {
      color: theme.palette.customGrey.medium,
    },
    '&.pause .anticon': {
      color: theme.palette.customGrey.medium,
    },
    '&.error': {
      color: theme.palette.error.main,
    },
    '&.error .anticon': {
      color: theme.palette.error.main,
    },
  };
});

export const Address = styled(Text)(({ theme }) => {
  return {
    color: theme.palette.customGrey.main,
  };
});

export const ArrowDown = styled(ArrowDownwardIcon)(() => {
  return {
    fontSize: 12,
  };
});

export const ArrowUp = styled(ArrowUpwardIcon)(() => {
  return {
    fontSize: 12,
  };
});

export const Pause = styled(PauseIcon)(() => {
  return {
    fontSize: 12,
  };
});
