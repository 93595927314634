import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Route, Routes } from "react-router-dom";
import { CircularProgress, Container } from "@mui/material";
import { userApi } from "lib/api";
import { settings } from "lib/ducks";
import { AccessType, accessTypeEnum } from "lib/enums";
import { SideBar, Main } from "components/layouts";
import { LogoWrapper, BackgroundImage } from "components/common";
import routes from "routes";
import NotFound from "pages/NotFound";

const AppLayout = observer(() => {
  const me = async () => {
    try {
      const user = await userApi.me();
      settings.setMe(user);
    } catch (error) {}
  };
  useEffect(() => {
    me();
  }, []);
  const profile = settings.getMe();
  return profile ? (
    <>
      <SideBar />
      <Main open={settings.sideBarOpen}>
        <Routes>
          {routes.map(({ path, component: Component, access }) => {
            return (
              profile?.access?.some((userAccess: AccessType) =>
                access.includes(userAccess as unknown as accessTypeEnum),
              ) && (
                <Route
                  key={`route-key-${path}`}
                  path={path}
                  element={<Component />}
                />
              )
            );
          })}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Main>
    </>
  ) : (
    <BackgroundImage style={{ flexDirection: "column" }}>
      <LogoWrapper />
      <CircularProgress />
    </BackgroundImage>
  );
});
export default AppLayout;
