import { useQuery } from 'react-query';
import { orgUnitApi } from '../../api';

const useFetch = (page: number | undefined) => {
  const { isLoading, data, refetch } = useQuery(
    ['fetchOrgUnit', page],
    () => orgUnitApi.fetch({ page }),
    {
      enabled: Boolean(page),
    }
  );
  return { isLoading, data, refetch };
};

export default useFetch;
