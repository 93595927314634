import React from "react";
type Props = {
  type?: string;
  className?: string;
};
export const DoorIcon = ({ type, className }: Props) => {
  let fill = "none";
  let stroke = "none";
  switch (type) {
    case "error":
      fill = "#F29100";
      stroke = "#7C7D7C";
      break;
    default:
      fill = "#D9D9D9";
      stroke = "#D9D9D9";
  }
  return (
    <svg
      className={className}
      width="23"
      height="37"
      viewBox="0 0 23 37"
      fill="none">
      <rect
        x="1.19794"
        y="1.39243"
        width="19.9657"
        height="29.5492"
        fill="white"
        stroke={stroke}
        stroke-width="2.39588"
      />
      <path
        d="M1.19794 31.3723V1.84539L13.1773 5.75356V35.2804L1.19794 31.3723Z"
        fill={fill}
        stroke={fill}
        stroke-width="2.39588"
      />
    </svg>
  );
};
