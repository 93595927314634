import React, { useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import { Button, CardContent } from '@mui/material';
import {
  GridActionsCellItem,
  GridRowParams,
  GridValueGetterParams,
} from '@mui/x-data-grid';

import { Header } from '../../components/layouts';
import { useDeleteEmployee, useEmployees } from '../../lib/hooks';
import { Card, Breadcrumbs, BaseDataGrid } from '../../components/ui';
import { PositionsType, positionsType } from '../../lib/enums';
import { Employee } from '../../lib/types';

const route = '/admin/employee';
const title = 'Сотрудники';
const List = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState<number>();
  const { isLoading, data, refetch } = useEmployees(page);
  const mutation = useDeleteEmployee();
  const deleteRecord = (id: number) => {
    mutation.mutate(id, {
      onSuccess: () => {
        refetch();
      },
    });
  };
  const columns = [
    {
      field: 'firstName',
      headerName: 'Фамилия',
      sortable: false,
      flex: 1,
    },
    {
      field: 'middleName',
      headerName: 'Имя',
      sortable: false,
      flex: 1,
    },
    {
      field: 'lastName',
      headerName: 'Отчество',
      sortable: false,
      flex: 1,
    },
    {
      field: 'position',
      headerName: 'Должность',
      width: 200,
      sortable: false,
      valueGetter: ({ value }: GridValueGetterParams) => {
        return positionsType[value as PositionsType];
      },
      flex: 1,
    },
    {
      field: 'id',
      type: 'actions',
      getActions: (params: GridRowParams<Employee>) => {
        return [
          <GridActionsCellItem
            label="Удалить"
            onClick={() => deleteRecord(params.row.id)}
            icon={<DeleteIcon />}
          />,
        ];
      },
    },
  ];
  const hanleChangePage = (page: number) => {
    setPage(page);
  };
  return (
    <>
      <Header
        title={title}
        right={
          <Button onClick={() => navigate(`${route}/new`)}>Создать</Button>
        }
      />
      <Breadcrumbs crumbs={[{ label: 'Главная', to: '/' }, { label: title }]} />
      <Card>
        <CardContent>
          <BaseDataGrid
            style={{ height: 'calc(100vh - 235px)' }}
            loading={isLoading}
            columns={columns}
            rows={data?.data}
            page={page}
            hanleChangePage={hanleChangePage}
            rowCount={data?.totalCount}
            onRowClick={({ row }: any) => {
              navigate(`${route}/${row.id}/edit`);
            }}
          />
        </CardContent>
      </Card>
    </>
  );
};
export default List;
