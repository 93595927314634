import { styled } from '@mui/material/styles';
import MenuFoldOutlined from '@mui/icons-material/Menu';
import { Container } from '.';

export const HeaderContainer = styled(Container)`
  font-size: 16px;
  line-height: 24px;
`;
export const MenuIconContainer = styled('div')`
  padding: 8px 16px 0px 16px;
`;
export const MenuFoldOutlinedStyled = styled(MenuFoldOutlined)(({ theme }) => {
  return {
    cursor: 'pointer',
    marginRight: '8px 16px 8px 0px',
    color: theme.palette.primary.main,
  };
});
