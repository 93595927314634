import React from "react";
import clsx from "clsx";
import { Grid, Skeleton } from "@mui/material";
import { observer } from "mobx-react-lite";

import { dispatcher, settings } from "lib/ducks";
import { Kns, OrgUnit, Vns } from "lib/types";
import {
  deviceEmergencyCodeEnum,
  deviceWorkStatusEnum,
  facilityTypeEnum,
} from "lib/enums";
import { someIncludes } from "lib/helpers";

import {
  ErrorIcon,
  GreenPointIcon,
  WaterContainerIcon,
  UnlockIcon,
  LockIcon,
  FloodIcon,
  RepairIcon,
} from "./../../components/icons";
import HorizontList from "./../../components/HorizontList";

import {
  KnsVnsListInfoContainer,
  KnsVnsListRow,
  CircleLetter,
  ItemIcon,
  Address,
  ArrowDown,
  KnsVnsListRowContainer,
  CloseOutlinedStyled,
  KnsVnsListCloseContainer,
} from "./styled";

type KnsVns = Kns & Vns;
export default observer(() => {
  const { active } = dispatcher;

  const hasErrorKns = dispatcher.hasErrorKns(
    dispatcher.filter.organizationUnitId,
  );

  const hasErrorVns = dispatcher.hasErrorVns(
    dispatcher.filter.organizationUnitId,
  );

  const handleClose = () => {
    dispatcher.showList = false;
  };

  return (
    <>
      <KnsVnsListInfoContainer>
        <KnsVnsListCloseContainer onClick={handleClose}>
          <CloseOutlinedStyled />
        </KnsVnsListCloseContainer>
        <HorizontList
          key={`horizont-list-org-unit`}
          list={[
            {
              label: "Все",
              hasError: false,
              isActive: dispatcher.filter.organizationUnitId === null,
              onClick: () => (dispatcher.filter.organizationUnitId = null),
            },
            ...(settings
              .getMe()
              ?.role?.organizationUnits.map((orgUnit: OrgUnit) => {
                return {
                  label: orgUnit.name,
                  hasError:
                    dispatcher.hasErrorKns(orgUnit.id) ||
                    dispatcher.hasErrorVns(orgUnit.id),
                  isActive: dispatcher.filter.organizationUnitId === orgUnit.id,
                  onClick: () =>
                    (dispatcher.filter.organizationUnitId = orgUnit.id),
                };
              }) ?? []),
          ]}
        />
        <HorizontList
          key={`horizont-list-type`}
          list={[
            {
              label: "Все",
              hasError: false,
              isActive: dispatcher.filter.type === null,
              onClick: () => (dispatcher.filter.type = null),
            },
            {
              label: "КНС",
              hasError: hasErrorKns,
              isActive: dispatcher.filter.type === facilityTypeEnum.kns,
              onClick: () => (dispatcher.filter.type = facilityTypeEnum.kns),
            },
            {
              label: "ВНС",
              hasError: hasErrorVns,
              isActive: dispatcher.filter.type === facilityTypeEnum.vns,
              onClick: () => (dispatcher.filter.type = facilityTypeEnum.vns),
            },
          ]}
        />
        <KnsVnsListRowContainer>
          {dispatcher.isLoading ? (
            <>
              {Array(12)
                .fill(null)
                .map((_, index: number) => (
                  <KnsVnsListRow key={`kns-vns-list-row-skeleton-${index}`}>
                    <Skeleton />
                  </KnsVnsListRow>
                ))}
            </>
          ) : (
            <>
              {/* @ts-ignore */}
              {dispatcher.getList().map((item: KnsVns) => {
                return (
                  <KnsVnsListRow
                    key={`KnsVnsListRow-${item.type}--${item.id}`}
                    container
                    item
                    xs={12}
                    className={clsx({
                      active: active
                        ? item.type === active.type && item.id === active.id
                        : false,
                    })}
                    onClick={() => {
                      dispatcher.active = item;
                    }}>
                    <ItemIcon item xs={1}>
                      {item.onRepair ? (
                        <RepairIcon />
                      ) : someIncludes(item.emergencyCode, [
                          deviceEmergencyCodeEnum.nonEmergency,
                        ]) ? (
                        <GreenPointIcon />
                      ) : (
                        <ErrorIcon />
                      )}
                    </ItemIcon>
                    <Grid item xs={4} sm={4} md={2}>
                      {item.name
                        ? item.name
                        : item.type === facilityTypeEnum.kns
                        ? `КНС №${item.deviceCode}`
                        : `ВНС №${item.deviceCode}`}
                    </Grid>
                    <Address item xs={7} sm={7} md={2}>
                      {item?.location?.shortAddress ?? "-"}
                    </Address>
                    <ItemIcon item xs={1}>
                      <CircleLetter>
                        {item.workStatus === deviceWorkStatusEnum.auto && "А"}
                        {item.workStatus === deviceWorkStatusEnum.dispatch &&
                          "Д"}
                        {item.workStatus === deviceWorkStatusEnum.manual && "Р"}
                      </CircleLetter>
                    </ItemIcon>
                    <Grid container item xs={7} md={4}>
                      {item.type === facilityTypeEnum.kns ? (
                        <Grid item xs={12}>
                          {item.pumpEfficiency} м<sup>3</sup> /c
                        </Grid>
                      ) : (
                        <>
                          <Grid item xs={4}>
                            <ArrowDown fontSize="small" color="error" />
                            <span>
                              {item.pressureLevelIn} кгс/см<sup>3</sup>
                            </span>
                          </Grid>
                          <Grid item xs={4}>
                            <ArrowDown fontSize="small" color="error" />
                            <span>
                              {item.pressureLevelOut} кгс/см<sup>3</sup>
                            </span>
                          </Grid>
                          <Grid item xs={4} />
                        </>
                      )}
                    </Grid>
                    <Grid item xs={2} md={1}>
                      {item.type === facilityTypeEnum.kns && (
                        <>
                          <WaterContainerIcon
                            type={clsx({
                              error: someIncludes(item.emergencyCode, [
                                deviceEmergencyCodeEnum.pitOverflow,
                              ]),
                            })}
                            style={{
                              position: "relative",
                              top: "3px",
                            }}
                          />
                          <span>{item.liquidLevel} %</span>
                        </>
                      )}
                    </Grid>
                    <Grid item xs={2} md={1}>
                      {item.reedSwitchInformation ? (
                        <UnlockIcon />
                      ) : (
                        <LockIcon type="disabled" />
                      )}

                      <FloodIcon
                        type={clsx({
                          error: someIncludes(item.emergencyCode, [
                            deviceEmergencyCodeEnum.roomFlooding,
                          ]),
                        })}
                      />
                    </Grid>
                  </KnsVnsListRow>
                );
              })}
            </>
          )}
        </KnsVnsListRowContainer>
      </KnsVnsListInfoContainer>
    </>
  );
});
