import { useQuery } from 'react-query';
import { journalApi } from '../../api';
import { FacilityType } from '../../enums';

export type FilterJournals = {
  type?: FacilityType;
  objectId?: number;
  organizationUnitId?: number;
};
const useFetch = (
  page: number | undefined,
  pageSize: number | undefined,
  filter?: FilterJournals,
) => {
  const { isLoading, data, refetch } = useQuery(
    ['fetchJournal', page, pageSize, filter],
    () => journalApi.fetch({ page, pageSize, ...filter }),
    {
      enabled: Boolean(page) && Boolean(pageSize),
    },
  );
  return { isLoading, data, refetch };
};

export default useFetch;
