import React from "react";
import { observer } from "mobx-react-lite";
import { dispatcher } from "../../../../lib/ducks";
import { deviceWorkStatusEnum, facilityTypeEnum } from "../../../../lib/enums";
import { calcHeight } from "../../../../lib/helpers";

import HorizontList from "../../components/HorizontList";

import Header from "./Header";
import Info from "./Info";
import JournalList from "./JournalList";
import { KnsVnsListInfoContainer, DivScroll } from "./styled";

export default observer(() => {
  const { active, showList } = dispatcher;
  if (active === null) {
    return null;
  }
  return (
    <KnsVnsListInfoContainer>
      <Header />
      <HorizontList
        key="horizont-list-vns-device-work-status"
        list={[
          {
            label: "Диспетчерский",
            isActive: active.workStatus === deviceWorkStatusEnum.dispatch,
          },
          {
            label: "Автоматический",
            isActive: active.workStatus === deviceWorkStatusEnum.auto,
          },
          {
            label: "Ручной",
            isActive: active.workStatus === deviceWorkStatusEnum.manual,
          },
        ]}
      />
      <DivScroll height={calcHeight(facilityTypeEnum.vns, showList)}>
        <Info />
        <JournalList />
      </DivScroll>
    </KnsVnsListInfoContainer>
  );
});
