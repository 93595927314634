import queryString from 'query-string';
import 'whatwg-fetch';

import tokenManager from './../tokenManager';

export const apiUrl = `${process.env.REACT_APP_API_URL || ''}/api`;
export const sseUrl = `${process.env.REACT_APP_API_URL || ''}/sse`;

export type FetchListParams<T = {}> = {
  where?: any;
  search?: string;
  page: number;
  pageSize: number;
  returnDeleted?: boolean;
  orderBy?: string;
  byDescending?: boolean;
} & T;

export const initialFetchListParams = {
  page: 1,
  pageSize: 20,
};

export type ListResponse<Type> = {
  data: Type[];
  totalCount: number;
  pageSize: number;
  totalPages: number;
};

export async function fetchTokenRequest(
  url: string,
  params: any,
): Promise<any> {
  const response = await window.fetch(`${apiUrl}${url}`, {
    ...params,
    headers: {
      ...params.headers,
      Authorization: `Bearer ${tokenManager.getToken()}`,
    },
  });
  if (response.ok) {
    if (response.status === 204) {
      return null;
    }
    return await response.json();
  } else {
    if (response.status === 503) {
      throw new Error('Server is under maintenance');
    } else if (response.status >= 400) {
      let resp;

      if (response.status === 401) {
        window.location.href = '/login';
        // throw new Error(
        //   'Истек срок действия авторизации пользователя в системе, необходимо авторизоваться повторно'
        // );
      }

      try {
        resp = await response.text();
        // строго говоря вообще сервер не обязан отдавать какие-то описания в json
        // поэтому сильно и не расчитываем на это
        resp = JSON.parse(resp);
      } finally {
        throw new Error(
          resp
            ? resp.title || resp
            : `Unhadled error. Server status code ${response.status}`,
        );
      }
    }
  }
}

export class fetchRequest {
  static async get(path: string, data?: any, options: any = {}) {
    return await fetchTokenRequest(
      `${path}${data ? `?${queryString.stringify(data)}` : ''}`,
      options,
    );
  }

  static async post(path: string, data?: any, options: any = {}) {
    return await fetchTokenRequest(path, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      ...options,
    });
  }

  static async patch(path: string, data: any, options: any = {}) {
    return await fetchTokenRequest(path, {
      method: 'PATCH',
      body: JSON.stringify(data),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      ...options,
    });
  }

  static async put(path: string, data: any, options: any = {}) {
    return await fetchTokenRequest(path, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      ...options,
    });
  }

  static async delete(path: string, options: any = {}) {
    return await fetchTokenRequest(path, {
      method: 'DELETE',
      ...options,
    });
  }
}
