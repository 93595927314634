import React from 'react';
type Props = {
  type?: string;
  className?: string;
};
export default ({ type, className }: Props) => {
  let fill = 'none';
  switch (type) {
    case 'disabled':
      fill = '#CCD2E3';
      break;
    default:
      fill = '#6C98DA';
  }
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 8.75C3 7.81812 3 7.35218 3.15224 6.98463C3.35523 6.49458 3.74458 6.10523 4.23463 5.90224C4.60218 5.75 5.06812 5.75 6 5.75H10C10.9319 5.75 11.3978 5.75 11.7654 5.90224C12.2554 6.10523 12.6448 6.49458 12.8478 6.98463C13 7.35218 13 7.81812 13 8.75V8.75C13 10.1478 13 10.8467 12.7716 11.3981C12.4672 12.1331 11.8831 12.7172 11.1481 13.0216C10.5967 13.25 9.89782 13.25 8.5 13.25H7.5C6.10218 13.25 5.40326 13.25 4.85195 13.0216C4.11687 12.7172 3.53284 12.1331 3.22836 11.3981C3 10.8467 3 10.1478 3 8.75V8.75Z"
        stroke={fill}
      />
      <path
        d="M10.5 5.125V4.5C10.5 3.11929 9.38071 2 8 2V2C6.61929 2 5.5 3.11929 5.5 4.5V5.125"
        stroke={fill}
        strokeLinecap="round"
      />
      <circle cx="8" cy="9.5" r="1.25" fill={fill} />
    </svg>
  );
};
