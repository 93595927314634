import { Grid as MuiGrid, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { pumpStatuseEnum } from "lib/enums";

const COLOR = {
  grey: {
    ligth: "#D9D9D9",
    main: "#7c7d7c",
  },
  green: {
    main: "#4AAF48",
  },
  warning: {
    light: "#FFF50D",
    main: "#F29100",
  },
};

export const SchemaContainerPercent = styled("div")<{ load: number }>(
  ({ load, theme }) => {
    const isTop = load < 5;
    return {
      position: "absolute",
      width: "100%",
      textAlign: "center",
      color: isTop ? theme.palette.customGrey.main : "white",
      fontWeight: 700,
      fontSize: 21,
      bottom: `calc(${load}% - ${isTop ? 0 : 21}px)`,
    };
  },
);

export const LineContainer = styled(MuiGrid)(({ theme }) => {
  return {
    position: "relative",
    display: "flex",
    "& .line": {
      position: "absolute",
      right: -38,
      bottom: 0,
    },
    "& .horizont": {
      position: "absolute",
      right: 2,
      bottom: 0,
    },
  };
});

export const Round = styled("div")<{ deg?: number }>(({ deg = 145, theme }) => {
  return {
    width: 84,
    height: 84,
    borderRadius: "50%",
    // borderLeft: `8px solid ${COLOR.warning.main}`,
    // borderTop: `8px solid ${COLOR.warning.light}`,
    // borderRight: `8px solid ${COLOR.green.main}`,
    borderBottom: `8px solid white`,
    padding: "3px",
    position: "relative",
    marginBottom: 20,
    "& .value": {
      color: "white",
      position: "absolute",
      width: "calc(100% - 3px)",
      top: "50%",
      textAlign: "center",
      fontWeight: 700,
      transform: "translate(0%, -50%)",
    },
    "& .value .num": {
      fontSize: 32,
    },
    "& .arrow": {
      top: "calc(50% - 8.5px)",
      left: "calc(50% - 8px)",
      transform: `rotate(${deg}deg) translateX(50px) rotate(180deg)`,
      position: "absolute",
    },
    "& .background": {
      background: COLOR.grey.ligth,
      height: "100%",
      borderRadius: "50%",
    },
  };
});

export const SchemaContainerBackground = styled("div")(({ theme }) => {
  return {
    background: theme.palette.primary.main,
    height: "10%",
    width: "100%",
  };
});

export const SchemaContainer = styled(MuiGrid)<{ load?: number }>(
  ({ theme, load = 0 }) => {
    return {
      zIndex: 99,
      position: "relative",
      display: "flex",
      alignItems: "end",
      height: "100%",
      borderTop: `2px solid ${theme.palette.customGrey.main}`,
      borderLeft: `10px solid ${theme.palette.customGrey.main}`,
      borderRight: `10px solid ${theme.palette.customGrey.main}`,
      borderBottom: `10px solid ${theme.palette.customGrey.main}`,
      borderRadius: `0 0 64px 64px`,
      background: `linear-gradient(0deg, #9CC5DD ${load}%, #fff ${load}%)`,
    };
  },
);

export const PupmLabel = styled(MuiGrid)(() => {
  return {
    lineHeight: "18px",
    fontSize: 21,
    fontWeight: 400,
    textTransform: "uppercase",
    color: COLOR.grey.main,
  };
});

export const PupmStatus = styled(MuiGrid)<{ status: pumpStatuseEnum }>(
  ({ status }) => {
    let color = null;
    switch (status) {
      case pumpStatuseEnum.on:
        color = COLOR.green.main;
        break;
      default:
        color = COLOR.grey.ligth;
    }
    return {
      fontSize: 21,
      fontWeight: 700,
      textTransform: "lowercase",
      color,
    };
  },
);

export const PupmWarning = styled(MuiGrid)(() => {
  return {
    fontSize: 13,
    fontWeight: 700,
    textTransform: "lowercase",
    color: COLOR.warning.main,
  };
});

export const StyledBox = styled(Box)(({ theme }) => {
  return {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[24],
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    width: "800px",
    height: "450px",
  };
});

const Grid = styled(MuiGrid)<{ error?: boolean }>(() => {
  return {
    textTransform: "uppercase",
    fontWeight: 700,
    fontSize: 17,
  };
});

export const CheckLevel = styled("div")<{ type?: string }>(({ type }) => {
  let background = undefined;
  let borderColor = undefined;
  switch (type) {
    case "success":
      background = COLOR.green.main;
      break;
    case "warning":
      background = COLOR.grey.ligth;
      borderColor = COLOR.warning.main;
      break;
    default:
      background = COLOR.grey.ligth;
  }
  return {
    zIndex: 999,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    top: -8,
    right: -26,
    width: 42,
    height: 42,
    borderRadius: "50%",
    fontSize: 21,
    fontWeight: 700,
    color: "white",
    border: `5px solid transparent`,
    borderColor,
    background,
  };
});

export const StyledGrid = styled(Grid)<{ type?: string }>(
  ({ type = "default" }) => {
    let color = null;
    switch (type) {
      case "success":
        color = COLOR.green.main;
        break;
      case "warning":
        color = COLOR.warning.main;
        break;
      default:
        color = COLOR.grey.ligth;
    }
    return {
      position: "relative",
      color,
    };
  },
);

export const StyledFooterGrid = styled(Grid)<{ error?: boolean }>(
  ({ error = false }) => {
    const color = error ? COLOR.warning.main : COLOR.grey.ligth;
    return {
      display: "flex",
      justifyContent: "center",
      color,
    };
  },
);
