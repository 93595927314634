import React, { ReactNode, useEffect, FC } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate, useResolvedPath, useMatch } from 'react-router-dom';
import clsx from 'clsx';
import { IconButton, Grid } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';

import { settings, dispatcher } from '../../../lib/ducks';
import { drawerWidth } from '../../../components/layouts/Sidebar';

import { ErrorIcon } from './icons';
import {
  Item,
  ErrorIconContainer,
  MenuFoldOutlinedStyled,
  HeaderContainer,
  MenuIconContainer,
} from './styled';

enum headerRouteEnum {
  monitor = '/',
  journals = '/dispatcher-journals',
}
type Props = {
  right?: ReactNode;
};
const Header: FC<Props> = ({ right }) => {
  const navigate = useNavigate();
  const monitorPath = useResolvedPath(headerRouteEnum.monitor);
  const journalsPath = useResolvedPath(headerRouteEnum.journals);
  const handleHeaderClick = (url: string) => {
    navigate(url);
  };
  const { sideBarOpen } = settings;
  useEffect(() => {
    dispatcher.fetchData();
    dispatcher.initSse();
    return () => {
      dispatcher.closeSse();
    };
  }, []);
  useEffect(() => {
    if (sideBarOpen) {
      settings.setField('hideMenuButterSidebar', false);
    } else {
      settings.setField('hideMenuButterSidebar', true);
    }
  }, [sideBarOpen]);
  const isActiveMonitor = useMatch({
    path: monitorPath?.pathname ?? null,
    end: true,
  });
  const isActiveJournals = useMatch({
    path: journalsPath?.pathname ?? null,
    end: true,
  });
  return (
    <HeaderContainer
      style={{
        width: `calc(100% - 64px - ${
          settings.sideBarOpen ? drawerWidth : 0
        }px)`,
      }}>
      {sideBarOpen ? null : (
        <MenuIconContainer>
          <MenuFoldOutlinedStyled
            onClick={() => {
              settings.setField('hideMenuButterSidebar', false);
              settings.setField('sideBarOpen', true);
            }}
          />
        </MenuIconContainer>
      )}
      <Item
        onClick={() => handleHeaderClick(headerRouteEnum.monitor)}
        className={clsx({
          active: isActiveMonitor,
        })}>
        {!dispatcher.showList && isActiveMonitor && (
          <IconButton
            sx={{ p: 0, pr: 1 }}
            color="primary"
            disabled={dispatcher.showList}
            onClick={() => {
              dispatcher.showList = true;
            }}>
            <ExpandMore />
          </IconButton>
        )}
        <span>Монитор</span>
        {(dispatcher.hasErrorVns() || dispatcher.hasErrorKns()) && (
          <ErrorIconContainer>
            <ErrorIcon />
          </ErrorIconContainer>
        )}
      </Item>
      <Item
        onClick={() => handleHeaderClick(headerRouteEnum.journals)}
        className={clsx({
          active: isActiveJournals,
        })}>
        <span>Журналы</span>
      </Item>
      <Grid item xs />
      <Item>{right}</Item>
    </HeaderContainer>
  );
};

export default observer(Header);
