import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

export const InfoContainer = styled(Grid)(({ theme }) => {
  return {
    borderBottom: `1px solid ${theme.palette.primary.light}`,
  };
});

export const InfoRowContainer = styled(Grid)(() => {
  return {
    '&.pl16': {
      paddingLeft: '16px',
    },
    '&.pr16': {
      paddingRight: '16px',
    },
    '&.pl8': {
      paddingLeft: '8px',
    },
    '&.pr8': {
      paddingRight: '8px',
    },
  };
});

export const InfoRow = styled(Grid)(({ theme }) => {
  return {
    alignItems: 'center',
    margin: '8px 0px',
    borderRadius: '4px',
    '& .p8': {
      padding: '8px',
    },
    '&.disabled': {
      background: theme.palette.customGrey.light,
      color: theme.palette.customGrey.main,
    },
    '&.main': {
      background: theme.palette.primary.light,
    },
    '&.error': {
      background: theme.palette.error.light,
    },
  };
});

export const InputOutputPower = styled(Grid)(({ theme }) => {
  return {
    textAlign: 'center',
    borderLeft: `1px solid ${theme.palette.customGrey.main}`,
    borderRight: `1px solid ${theme.palette.customGrey.main}`,
  };
});
