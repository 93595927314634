import { createTheme } from '@mui/material/styles';
export const THEME_PALETTE = {
  black: {
    main: '#3C3939',
  },
  primary: {
    light: '#E5F3FB',
    main: '#6C98DA',
    contrastText: 'white',
  },
  error: {
    light: '#fce3e3',
    main: '#EB4343',
  },
  grey: {
    light: '#f7f8fb',
    main: '#9B9797',
    medium: '#CCD2E3',
    border: '#e0e0e0',
  },
};

declare module '@mui/material/styles' {
  interface Palette {
    customGrey: Palette['primary'] & { medium: string };
    black: Palette['primary'];
  }

  interface PaletteOptions {
    customGrey?: PaletteOptions['primary'];
    black?: PaletteOptions['primary'];
  }
}

const theme = createTheme({
  typography: {
    fontFamily: [
      'Inter',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
  },
  palette: {
    primary: THEME_PALETTE.primary,
    error: THEME_PALETTE.error,
    customGrey: THEME_PALETTE.grey,
    black: THEME_PALETTE.black,
  },
});
export default theme;
