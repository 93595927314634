import React from 'react';
type Props = {
  type?: string;
  className?: string;
  style?: any;
};
export default ({ type, className, style }: Props) => {
  let stroke = 'none';
  switch (type) {
    case 'error':
      stroke = '#EB4343';
      break;
    default:
      stroke = '#6C98DA';
  }
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}>
      <ellipse cx="8" cy="4" rx="5" ry="2" stroke={stroke} />
      <path
        d="M13 12C13 12.2626 12.8707 12.5227 12.6194 12.7654C12.3681 13.008 11.9998 13.2285 11.5355 13.4142C11.0712 13.5999 10.52 13.7472 9.91342 13.8478C9.30679 13.9483 8.65661 14 8 14C7.34339 14 6.69321 13.9483 6.08658 13.8478C5.47995 13.7472 4.92876 13.5999 4.46447 13.4142C4.00017 13.2285 3.63188 13.008 3.3806 12.7654C3.12933 12.5227 3 12.2626 3 12"
        stroke={stroke}
      />
      <path d="M13 4V12" stroke={stroke} />
      <path d="M3 4V12" stroke={stroke} />
    </svg>
  );
};
