import React from "react";
import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { ReactComponent as Logo } from "./logo.svg";

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  height: 280px;
  width: 350px;
`;
const StyledTypography = styled(Typography)`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 6px;
`;
const Container = styled.div`
  margin-bottom: 36px;
  display: flex;
  align-items: center;
`;
const LogoTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LogoWrapper = () => {
  return (
    <Container>
      <Logo style={{ margin: "0 7px" }} />
      <LogoTitleWrapper>
        <StyledTypography paragraph>
          Система диспетчиризации А2В
        </StyledTypography>
      </LogoTitleWrapper>
    </Container>
  );
};
