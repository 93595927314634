import React from 'react';
import { Grid, Switch } from '@mui/material';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';

import { dispatcher } from '../../../../lib/ducks';
import { LockIcon, UnlockIcon } from './../../components/icons';

import {
  InfoContainer,
  InfoRow,
  // MarginLeftAuto,
  // ArrowUp,
  InputOutputPower,
  ArrowDown,
  Pause,
  InfoRowContainer,
} from './styled';

import {
  facilityTypeEnum,
  pumpStatuseEnum,
  pumpStatus,
  motorStatusEnum,
  motorStatus,
  deviceEmergencyCode,
  deviceEmergencyCodeEnum,
  DeviceEmergencyCode,
} from '../../../../lib/enums';
import { Kns, Vns } from '../../../../lib/types';

export default observer(() => {
  const { active } = dispatcher;
  if (active === null) {
    return null;
  }
  return (
    <InfoContainer container>
      <InfoRowContainer item xs={12} md={6} className="pl16 pr8">
        <InfoRow
          container
          className={clsx({
            disabled: !active.reedSwitchInformation,
            main: active.reedSwitchInformation,
          })}>
          <Grid item className="p8">
            {active.reedSwitchInformation ? (
              <UnlockIcon />
            ) : (
              <LockIcon type="disabled" />
            )}
          </Grid>
          <Grid item xs={5} className="p8">
            Дверь {active.reedSwitchInformation ? 'открыта' : 'закрыта'}
          </Grid>
          {/* <MarginLeftAuto item>
            <Switch />
          </MarginLeftAuto> */}
        </InfoRow>

        {(active.type === facilityTypeEnum.kns &&
          (active as Kns)?.pumpStatuses?.map((status, index) => {
            return (
              <InfoRow
                container
                key={`info-row-kns-${index}`}
                className={clsx({
                  main: status === pumpStatuseEnum.on,
                  disabled: [
                    pumpStatuseEnum.reserve,
                    pumpStatuseEnum.off,
                  ].includes(status),
                })}>
                <Grid item className="p8">
                  {[pumpStatuseEnum.reserve, pumpStatuseEnum.off].includes(
                    status,
                  ) && <Pause />}
                </Grid>
                <Grid item xs={6} className="p8">
                  Насос №{index + 1} {pumpStatus[status]?.toLowerCase() ?? ''}
                </Grid>
              </InfoRow>
            );
          })) ??
          null}

        {(active.type === facilityTypeEnum.vns &&
          (active as unknown as Vns)?.motorsStatuses?.map((status, index) => {
            return (
              <InfoRow
                container
                className={clsx({
                  main: status === motorStatusEnum.on,
                  disabled: [
                    motorStatusEnum.reserve,
                    motorStatusEnum.off,
                  ].includes(status),
                })}>
                <Grid item className="p8">
                  {[motorStatusEnum.reserve, motorStatusEnum.off].includes(
                    status,
                  ) && <Pause />}
                </Grid>
                <Grid item xs={6} className="p8">
                  Двигатель №{index + 1}{' '}
                  {motorStatus[status]?.toLowerCase() ?? ''}
                </Grid>
                <InputOutputPower item xs={3}>
                  {/* @ts-ignore */}
                  {active.motorsEfficiency[index]} Гц
                </InputOutputPower>
              </InfoRow>
            );
          })) ??
          null}
      </InfoRowContainer>

      <InfoRowContainer item xs={12} md={6} className="pr16 pl8">
        {Object.keys(deviceEmergencyCode)
          .filter(
            (code) => Number(code) !== deviceEmergencyCodeEnum.nonEmergency,
          )
          .map((code: any, index: number) => {
            const isError = active.emergencyCode.includes(
              Number(code) as unknown as DeviceEmergencyCode,
            );
            return (
              <InfoRow
                container
                key={`info-row-device-emergency-code-${index}`}
                className={clsx({
                  disabled: !isError,
                  error: isError,
                })}>
                {/* <Grid item className="p8">
                <ArrowDown fontSize="small" color="error" />
              </Grid> */}
                <Grid item xs={12} className="p8">
                  {deviceEmergencyCode[code as unknown as DeviceEmergencyCode]}
                </Grid>
              </InfoRow>
            );
          })}
      </InfoRowContainer>
    </InfoContainer>
  );
});
