import React, { useEffect, useState } from "react";
import { Divider, List, ListItem, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { Marker, Popup, useMap, useMapEvents } from "react-leaflet";
import { renderToStaticMarkup } from "react-dom/server";
import { divIcon } from "leaflet";

import { dispatcher } from "lib/ducks";
import { Kns, Vns } from "lib/types";
import { someIncludes } from "lib/helpers";
import { deviceEmergencyCodeEnum, facilityTypeEnum } from "lib/enums";

import { ErrorIcon, GreenPointIcon, RepairIcon } from "./icons";
import { MarkerIconContainer } from "./styled";
import MarkerKnsVns from "./Marker";

export default observer(() => {
  const map = useMap();
  useMapEvents({
    moveend() {
      dispatcher.isTracking && handleMove();
    },
  });
  const [locations, setLocations] = useState<any>({} as any);
  const [activeDeviceCode, setActiveDeviceCode] = useState<number | null>(null);

  const handleMove = () => {
    let kns: Kns[] = [];
    let vns: Vns[] = [];
    Object.values(locations).forEach((location: any) => {
      const {
        location: { lat, lon },
      } = location;
      if (map.getBounds().contains([lat, lon])) {
        kns.push(
          ...location.popups.filter(
            (element: Kns | Vns) => element.type === facilityTypeEnum.kns,
          ),
        );
        vns.push(
          ...location.popups.filter(
            (element: Kns | Vns) => element.type === facilityTypeEnum.vns,
          ),
        );
      }
    });
    dispatcher.trackingKns = [...kns];
    dispatcher.trackingVns = [...vns];
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      let lat = position.coords.latitude;
      let lon = position.coords.longitude;
      map.setView([lat, lon]);
    });
  }, []);

  useEffect(() => {
    const { active } = dispatcher;
    if (active && active.deviceCode !== activeDeviceCode) {
      map.flyTo([active.location.lat - 0.005, active.location.lon - 0.02], 15);
      setActiveDeviceCode(active.deviceCode);
    }
  }, [dispatcher.active]);

  useEffect(() => {
    if (dispatcher.isTracking) {
      handleMove();
    } else {
      //если отслеживание отключено очищаем список отслежваемых кнс/внс
      dispatcher.trackingKns = [];
      dispatcher.trackingVns = [];
    }
  }, [dispatcher.isTracking]);

  useEffect(() => {
    const list = dispatcher.getList();
    const locations = list.reduce((previousValue: any, currentValue) => {
      previousValue[currentValue.locationId] = {
        location: currentValue.location,
        popups: previousValue[currentValue.locationId]?.popups
          ? [...previousValue[currentValue.locationId].popups, currentValue]
          : [currentValue],
      };
      return previousValue;
    }, {});
    setLocations(locations);
  }, [JSON.stringify(dispatcher.knses), JSON.stringify(dispatcher.vnses)]);

  return (
    <>
      {Object.keys(locations).map((locationId, index) => {
        const {
          location: { lat, lon },
          popups,
        }: any = locations[locationId];
        return (
          <Marker
            key={`marker-${locationId}-${index}`}
            position={[lat, lon]}
            eventHandlers={{
              click: () => {
                if (popups.length === 1) {
                  dispatcher.active = popups[0];
                }
              },
            }}
            icon={divIcon({
              className: "custom icon",
              html: renderToStaticMarkup(<MarkerKnsVns popups={popups} />),
            })}>
            {popups.length > 1 ? (
              <Popup key={`marker-popup-${locationId}-${index}`}>
                <List
                  key={`marker-list-${locationId}-${index}`}
                  sx={{
                    width: "100%",
                    maxWidth: 360,
                    bgcolor: "background.paper",
                  }}>
                  {popups.map((itemKnsVns: Kns | Vns, idx: number) => {
                    const { name, deviceCode, type, emergencyCode, onRepair } =
                      itemKnsVns;
                    const primary =
                      name && name !== ""
                        ? name
                        : `${
                            type === facilityTypeEnum.kns ? "KНС" : "ВНС"
                          } №${deviceCode}`;
                    return (
                      <div
                        key={`cont-map-component-popup-item-${locationId}-${index}-${idx}`}>
                        <ListItem
                          key={`map-component-popup-item-${locationId}-${index}-${idx}`}
                          onClick={() => {
                            dispatcher.active = itemKnsVns;
                          }}>
                          {null}
                          <MarkerIconContainer>
                            {onRepair ? (
                              <RepairIcon />
                            ) : someIncludes(emergencyCode, [
                                deviceEmergencyCodeEnum.nonEmergency,
                              ]) ? (
                              <GreenPointIcon />
                            ) : (
                              <ErrorIcon />
                            )}
                          </MarkerIconContainer>
                          <div>
                            <Typography gutterBottom component="div">
                              {primary}
                            </Typography>
                          </div>
                        </ListItem>
                        <Divider />
                      </div>
                    );
                  })}
                </List>
              </Popup>
            ) : null}
          </Marker>
        );
      })}
    </>
  );
});
