import { useMutation } from 'react-query';
import { vnsApi } from '../../api';
import { Vns } from '../../types';

const useAddUpdate = () => {
  const mutation = useMutation((vns: Vns): any => {
    return vns?.id ? vnsApi.updatePut(vns) : vnsApi.add(vns);
  });
  return mutation;
};

export default useAddUpdate;
