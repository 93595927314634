import React, { useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import { Button, CardContent } from '@mui/material';
import {
  GridActionsCellItem,
  GridRowParams,
  GridValueGetterParams,
} from '@mui/x-data-grid';

import { Header } from '../../components/layouts';
import { useDeleteRole, useRoles } from '../../lib/hooks';
import { Card, Breadcrumbs, BaseDataGrid } from '../../components/ui';
import { AccessType, accessType } from '../../lib/enums';
import { OrgUnit, Role } from '../../lib/types';

const route = '/admin/roles';
const title = 'Роли';
const List = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState<number>();
  const { isLoading, data, refetch } = useRoles(page);
  const mutation = useDeleteRole();
  const deleteRecord = (id: number) => {
    mutation.mutate(id, {
      onSuccess: () => {
        refetch();
      },
    });
  };
  const columns = [
    {
      field: 'name',
      width: 200,
      headerName: 'Роль',
      sortable: false,
    },
    {
      field: 'access',
      width: 200,
      headerName: 'Доступы',
      sortable: false,
      valueGetter: ({ value }: GridValueGetterParams) => {
        return (
          value
            ?.map((access: { type: AccessType }) => {
              return accessType[access.type] ?? '';
            })
            .join(', ') ?? '-'
        );
      },
      flex: 1,
    },
    {
      field: 'organizationUnits',
      width: 200,
      headerName: 'Подразделения',
      sortable: false,
      valueGetter: ({ value }: GridValueGetterParams) => {
        return value?.map(({ name }: OrgUnit) => name).join(', ') ?? '-';
      },
      flex: 1,
    },
    {
      field: 'id',
      type: 'actions',
      getActions: (params: GridRowParams<Role>) => {
        return [
          <GridActionsCellItem
            label="Удалить"
            onClick={() => deleteRecord(params.row.id)}
            icon={<DeleteIcon />}
          />,
        ];
      },
    },
  ];
  const hanleChangePage = (page: number) => {
    setPage(page);
  };
  return (
    <>
      <Header
        title={title}
        right={
          <Button onClick={() => navigate(`${route}/new`)}>Создать</Button>
        }
      />
      <Breadcrumbs crumbs={[{ label: 'Главная', to: '/' }, { label: title }]} />
      <Card>
        <CardContent>
          <BaseDataGrid
            style={{ height: 'calc(100vh - 235px)' }}
            loading={isLoading}
            columns={columns}
            rows={data?.data}
            page={page}
            hanleChangePage={hanleChangePage}
            rowCount={data?.totalCount}
            onRowClick={({ row }: any) => {
              navigate(`${route}/${row.id}/edit`);
            }}
          />
        </CardContent>
      </Card>
    </>
  );
};
export default List;
