import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { MapContainer, TileLayer } from "react-leaflet";
import { Grid } from "@mui/material";
import { TrackChanges } from "@mui/icons-material";

import { dispatcher } from "lib/ducks";
import { facilityTypeEnum } from "lib/enums";

import Header from "./../components/Header";
import MapComponent from "./../components/MapComponent";

import { Container, StyledIconBtn } from "./components/styled";
import List from "./components/KnsVnsList";
import Kns from "./components/KnsInfo";
import Vns from "./components/VnsInfo";
import { SchemaIcon } from "../components/icons";
import { SchemaModal } from "./components/SchemaModal";

const Dispatcher = observer(() => {
  const { showList, active, isTracking } = dispatcher;
  const [open, setOpen] = useState<boolean>(false);

  const handleTracking = () => {
    dispatcher.isTracking = !isTracking;
  };

  const handleOpenSchema = () => {
    setOpen(true);
  };

  const handleCloseSchema = () => {
    setOpen(false);
  };

  return (
    <>
      <Header
        right={
          <>
            {active?.type === facilityTypeEnum.kns && (
              <StyledIconBtn
                onClick={handleOpenSchema}
                color="default"
                sx={{ mr: 1 }}>
                <SchemaIcon />
              </StyledIconBtn>
            )}
            <StyledIconBtn
              onClick={handleTracking}
              color={isTracking ? "primary" : "default"}>
              <TrackChanges />
            </StyledIconBtn>
          </>
        }
      />
      <SchemaModal open={open} onClose={handleCloseSchema} />
      <Container>
        <Grid container spacing={2}>
          {showList && (
            <Grid item xs={12} md={6}>
              <List />
            </Grid>
          )}
          <Grid item xs={12} md={6} alignSelf={"end"}>
            {active?.type === facilityTypeEnum.vns && <Vns />}
            {active?.type === facilityTypeEnum.kns && <Kns />}
          </Grid>
        </Grid>
      </Container>
      <MapContainer
        style={{ height: "100vh" }}
        center={[55.791278, 49.115734]}
        zoom={13}
        zoomControl={false}>
        <MapComponent />
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
      </MapContainer>
    </>
  );
});

export default Dispatcher;
