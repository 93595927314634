import React from "react";
type Props = {
  type?: string;
  className?: string;
};
export const EllipseIcon = ({ type, className }: Props) => {
  let fill = "none";
  switch (type) {
    case "error":
      fill = "#9CC5DD";
      break;
    default:
      fill = "#D9D9D9";
  }
  return (
    <svg
      className={className}
      width="22"
      height="33"
      viewBox="0 0 22 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.5629 20.4782C21.5629 27.3597 16.7359 32.9382 10.7815 32.9382C4.82703 32.9382 0 27.3597 0 20.4782C0 13.5968 10.7815 0.194519 10.7815 0.194519C10.7815 0.194519 21.5629 13.5968 21.5629 20.4782Z"
        fill={fill}
      />
    </svg>
  );
};
