import React from "react";
type Props = {
  height: number;
  className?: string;
  type?: string;
  width?: number;
  style?: any;
};
export const HorizontLineIcon = ({
  className,
  height,
  type,
  style,
  width = 101,
}: Props) => {
  let stroke = "none";
  let zIndex = 9;
  switch (type) {
    case "primary":
      stroke = "#9CC5DD";
      zIndex = 999;
      break;
    default:
      stroke = "#F0F4F5";
  }
  return (
    <svg
      className={className}
      width={width}
      height={height}
      style={{ zIndex, ...style }}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <line
        x1="20"
        y1="10"
        x2={width}
        y2="10"
        strokeWidth="20"
        stroke={stroke}
      />
    </svg>
  );
};
