import { useQuery } from 'react-query';
import { userApi } from '../../api';

export const useUsers = (page: number | undefined) => {
  const { isLoading, data } = useQuery(
    ['fetchUsers', page],
    () => userApi.fetch({ page }),
    {
      enabled: Boolean(page),
    }
  );
  return { isLoading, data };
};
