import React from 'react';
type Props = {
  type?: string;
  className?: string;
};
const Icon = ({ type, className }: Props) => {
  let stroke = 'none';
  switch (type) {
    case 'error':
      stroke = '#EB4343';
      break;
    default:
      stroke = '#CCD2E3';
  }
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 11C7.77987 11 7.56588 10.891 7.39124 10.69C7.21659 10.489 7.09105 10.2072 7.03407 9.88823"
        stroke={stroke}
        strokeLinecap="round"
      />
      <path
        d="M7.0032 2.71239C7.11848 2.51437 7.17611 2.41535 7.227 2.35335C7.62706 1.86589 8.37294 1.86589 8.773 2.35335C8.82389 2.41535 8.88152 2.51437 8.9968 2.7124L10.0287 4.48513C10.7567 5.73568 11.3242 7.07294 11.7179 8.46535V8.46535C12.3611 10.7404 10.6519 13 8.28758 13H7.71242C5.34814 13 3.63886 10.7404 4.28212 8.46535V8.46535C4.67581 7.07294 5.24333 5.73568 5.97128 4.48513L7.0032 2.71239Z"
        stroke={stroke}
      />
    </svg>
  );
};
export default Icon;
