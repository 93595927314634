import React from "react";
import { Grid, IconButton, Menu, MenuItem } from "@mui/material";
import { observer } from "mobx-react-lite";

import { dispatcher } from "../../../../lib/ducks";
import {
  deviceEmergencyCodeEnum,
  facilityTypeEnum,
} from "../../../../lib/enums";
import { useAddUpdateVns, useAddUpdateKns } from "../../../../lib/hooks";
import { someIncludes } from "../../../../lib/helpers";
import { Kns, Vns } from "../../../../lib/types";
import { ErrorIcon, GreenPointIcon, RepairIcon } from "../../components/icons";

import {
  HeaderGridIcon,
  HeaderContainer,
  CloseOutlinedStyled,
  HeaderGridClose,
  Label,
  Title,
} from "./styled";

export default observer(() => {
  const { active } = dispatcher;

  if (active === null) {
    return null;
  }

  const mutateKns = useAddUpdateKns();
  const mutateVns = useAddUpdateVns();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = async (onRepair: boolean) => {
    switch (active.type) {
      case facilityTypeEnum.kns:
        await mutateKns.mutateAsync({ ...(active as Kns), onRepair });
        break;
      default:
        await mutateVns.mutateAsync({ ...(active as Vns), onRepair });
    }
    dispatcher.fetchData();
    handleClose();
  };

  return (
    <HeaderContainer container>
      <HeaderGridIcon item xs={1}>
        {active.onRepair ? (
          <IconButton size="small" onClick={handleClick}>
            <RepairIcon />
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}>
              <MenuItem onClick={() => handleChange(false)}>В работе</MenuItem>
            </Menu>
          </IconButton>
        ) : someIncludes(active.emergencyCode, [
            deviceEmergencyCodeEnum.nonEmergency,
          ]) ? (
          <GreenPointIcon />
        ) : (
          <>
            <IconButton size="small" onClick={handleClick}>
              <ErrorIcon />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}>
              <MenuItem onClick={() => handleChange(true)}>В ремонте</MenuItem>
            </Menu>
          </>
        )}
      </HeaderGridIcon>
      <Title item xs={3}>
        {active.name
          ? active.name
          : active.type === facilityTypeEnum.kns
          ? "КНС"
          : "ВНС"}
      </Title>
      <Grid item xs={3}>
        <Label>Код устройства</Label>
        <div>{active.deviceCode}</div>
      </Grid>
      <Grid item xs={3}>
        <Label>----</Label>
        <div>{active.location?.shortAddress}</div>
      </Grid>
      <HeaderGridClose>
        <CloseOutlinedStyled onClick={() => (dispatcher.active = null)} />
      </HeaderGridClose>
    </HeaderContainer>
  );
});
