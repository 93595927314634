import React, { ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import { ErrorIcon } from './icons';

import { Item, ErrorIconContainer } from './styled';
import clsx from 'clsx';
type ListItem = {
  label: string | ReactNode;
  onClick?: () => void;
  hasError?: boolean;
  isActive?: boolean;
};
type Props = {
  list: ListItem[];
  right?: any;
};
const Container = styled('div')(({ theme }) => {
  return {
    display: 'flex',
    padding: theme.spacing(2),
    borderBottom: '1px solid #e5f3fb',
    overflow: 'auto',
    fontSize: 14,
  };
});
const ItemLeft = styled('div')`
  display: flex;
`;
const ItemRight = styled('div')`
  margin-left: auto;
`;
const StyledItem = styled(Item)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const List = (props: Props) => {
  return (
    <Container>
      <ItemLeft>
        {props.list.map(
          ({ label, hasError, isActive, onClick }, index: number) => {
            return (
              <StyledItem
                key={`horizont-list-item-${index}`}
                onClick={() => {
                  onClick && onClick();
                }}
                className={clsx({ active: isActive })}>
                {hasError && (
                  <ErrorIconContainer>
                    <ErrorIcon />
                  </ErrorIconContainer>
                )}
                {label}
              </StyledItem>
            );
          },
        )}
      </ItemLeft>
      <ItemRight>{props.right}</ItemRight>
    </Container>
  );
};
export default List;
