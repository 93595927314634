import { facilityTypeEnum } from "./enums";

export const getQueryParams = (str?: string): any => {
  const search = str ? str : window.location.search.slice(1);
  return search === ""
    ? {}
    : Object.fromEntries(search.split("&").map((param) => param.split("=")));
};

export const setQueryParams = (params: object): void => {
  const oldParams = getQueryParams();
  const seacrh = Object.entries({ ...oldParams, ...params })
    .map((param) => {
      if (param.includes(undefined)) {
        return null;
      }
      return param.join("=");
    })
    .filter((param) => {
      return param !== null;
    })
    .join("&");
  const refresh = `${window.location.protocol}//${window.location.host}${window.location.pathname}?${seacrh}`;
  window.history.pushState({ path: refresh }, "", refresh);
};

export const localeDate = (
  date: string | number | Date,
  locale: string = "ru-Ru",
  option?: any,
) => {
  return new Date(date).toLocaleDateString(locale, option);
};

export const localeTime = (
  date: string | number | Date,
  locale: string = "ru-Ru",
  option: any = {
    hour: "2-digit",
    minute: "2-digit",
  },
) => {
  return new Date(date).toLocaleTimeString(locale, option);
};

export const someIncludes = (someArr: any[], includesArr: any[]) => {
  return someArr.some((access) => includesArr.includes(access));
};

export const calcHeight = (type: facilityTypeEnum, showList: boolean) => {
  if (!showList) {
    return "calc(100vh - 270px)";
  }
  return type === facilityTypeEnum.kns
    ? "calc(100vh - 535px)"
    : "calc(100vh - 270px)";
};
