import React, { useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import { Button, CardContent } from '@mui/material';
import { GridActionsCellItem, GridRowParams } from '@mui/x-data-grid';

import { Header } from '../../components/layouts';
import { useDeleteLocation, useLocations } from '../../lib/hooks';
import { Card, Breadcrumbs, BaseDataGrid } from '../../components/ui';
import { Location } from '../../lib/types';

const route = '/admin/locations';
const title = 'Объекты';
const List = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState<number>();
  const { isLoading, data, refetch } = useLocations(page);
  const mutation = useDeleteLocation();
  const deleteRecord = (id: number) => {
    mutation.mutate(id, {
      onSuccess: () => {
        refetch();
      },
    });
  };
  const columns = [
    {
      field: 'name',
      width: 200,
      headerName: 'Наименование',
      sortable: false,
    },
    {
      field: 'shortAddress',
      width: 300,
      headerName: 'Адрес',
      sortable: false,
    },
    {
      field: 'address',
      flex: 1,
      headerName: 'Полный адрес',
      sortable: false,
    },
    {
      field: 'id',
      type: 'actions',
      getActions: (params: GridRowParams<Location>) => {
        return [
          <GridActionsCellItem
            label="Удалить"
            onClick={() => deleteRecord(params.row.id)}
            icon={<DeleteIcon />}
          />,
        ];
      },
    },
  ];
  const hanleChangePage = (page: number) => {
    setPage(page);
  };
  return (
    <>
      <Header
        title={title}
        right={
          <Button onClick={() => navigate(`${route}/new`)}>Создать</Button>
        }
      />
      <Breadcrumbs crumbs={[{ label: 'Главная', to: '/' }, { label: title }]} />
      <Card>
        <CardContent>
          <BaseDataGrid
            style={{ height: 'calc(100vh - 235px)' }}
            loading={isLoading}
            columns={columns}
            rows={data?.data}
            page={page}
            hanleChangePage={hanleChangePage}
            paginationMode="server"
            rowCount={data?.totalCount}
            onRowClick={({ row }: any) => {
              navigate(`${route}/${row.id}/edit`);
            }}
          />
        </CardContent>
      </Card>
    </>
  );
};
export default List;
