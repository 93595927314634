import styled from '@emotion/styled';

const Item = styled('div')`
  display: flex;
  text-align: center;
  align-items: center;
  padding: 8px 16px;
  cursor: pointer;
  &.active {
    background: #e5f3fb;
    border-radius: 12px;
  }
`;

export default Item;
