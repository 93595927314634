import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useInView } from 'react-intersection-observer';
import { Button, Grid, IconButton, Skeleton } from '@mui/material';
import { UseMutationResult } from 'react-query';

import { dispatcher } from '../../../../lib/ducks';
import {
  useAddUpdateJournal,
  useAddUpdateJournalVehicle,
  useDeleteJournalVehicle,
  useInfinityJournal,
} from './../../../../lib/hooks';
import { Journal } from '../../../../lib/types';
import { localeDate, localeTime } from '../../../../lib/helpers';

import { handleSubmitJornal } from '../../journals/List';
import ModalForm from '../../journals/components/ModalForm';
import {
  MarginLeftAuto,
  Title,
  JournalContainer,
  Label,
  JournalEditIcon,
  JournaBody,
  GridMarginTop16,
} from './styled';

export default observer(() => {
  const [open, setOpen] = useState(false);
  const [jornalId, setJournalId] = useState<number | null>(null);
  const { ref, inView } = useInView();
  const { active } = dispatcher;
  if (active === null) {
    return null;
  }
  const journal = useInfinityJournal({
    type: active.type,
    objectId: active.id,
  });
  const mutationJournal = useAddUpdateJournal();
  const mutationJournalVehicle = useAddUpdateJournalVehicle();
  const deleteJournalVehicle = useDeleteJournalVehicle();
  useEffect(() => {
    if (inView) {
      journal.fetchNextPage();
    }
  }, [inView]);
  const handleSubmit = async (
    dataJournal: Journal,
    deleteJournalVehicleIndexes: number[],
  ) => {
    handleSubmitJornal(
      {
        ...dataJournal,
        type: active.type,
        objectId: active.id,
      },
      deleteJournalVehicleIndexes,
      mutationJournal as UseMutationResult,
      deleteJournalVehicle as UseMutationResult,
      mutationJournalVehicle as UseMutationResult,
      () => {
        setOpen(false);
        setJournalId(null);
        journal.refetch();
      },
    );
  };
  return (
    <JournalContainer container>
      {open && (
        <ModalForm
          open={true}
          hiddenKnsVnsSelect
          journalId={jornalId}
          handleSubmit={handleSubmit}
          isLoading={
            mutationJournal.isLoading || mutationJournalVehicle.isLoading
          }
          initValue={{
            type: active.type,
            objectId: active.id,
          }}
          handleClose={() => setOpen(false)}
        />
      )}
      <Title item>Журнал</Title>
      <MarginLeftAuto item>
        <Button
          variant="outlined"
          onClick={() => {
            setJournalId(null);
            setOpen(true);
          }}>
          + Добавить запись
        </Button>
      </MarginLeftAuto>
      {journal.data?.pages.map((page: any) => {
        return page.data.map((journal: Journal) => {
          return (
            <GridMarginTop16
              key={`journal-list-item-${journal?.id}`}
              item
              xs={12}>
              <Grid container>
                <Grid item>
                  {journal?.date ? (
                    <>
                      {localeTime(journal?.date)}{' '}
                      <Label display="contents">
                        {localeDate(journal?.date)}
                      </Label>{' '}
                    </>
                  ) : (
                    '-'
                  )}
                </Grid>
                <MarginLeftAuto>
                  <IconButton
                    color="primary"
                    size="small"
                    onClick={() => {
                      setJournalId(Number(journal.id));
                      setOpen(true);
                    }}>
                    <JournalEditIcon />
                  </IconButton>
                </MarginLeftAuto>
              </Grid>
              <JournaBody container>
                <Grid item xs={6} sm={3}>
                  <Label>Адрес</Label>
                  <div>{journal?.object?.location?.shortAddress ?? '-'}</div>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Label>Причина</Label>
                  <div>{journal?.reason ?? '-'}</div>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Label>Исполнитель</Label>
                  <div>{journal?.employee?.firstName ?? '-'}</div>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Label>Диспетчер</Label>
                  <div>{journal?.user?.employee?.firstName ?? '-'}</div>
                </Grid>
                <GridMarginTop16 item xs={12}>
                  <Label>Выполнена/Транспорт</Label>
                  <div>
                    {journal?.vehicles?.map((veh, index: number) => {
                      const isLast = journal.vehicles.length === index + 1;
                      return (
                        <>
                          {Boolean(index) && ' '}
                          {veh.licensePlate}{' '}
                          <Label display="contents">
                            ({veh.startDate && localeTime(veh.startDate)}{' '}
                            {veh.endDate && `- ${localeTime(veh.endDate)}`})
                            {!isLast && ','}
                          </Label>
                        </>
                      );
                    })}
                  </div>
                </GridMarginTop16>
                <GridMarginTop16 item xs={12}>
                  <Label>Меры устранения</Label>
                  <div>{journal.solution}</div>
                </GridMarginTop16>
              </JournaBody>
            </GridMarginTop16>
          );
        });
      })}
      {journal.isLoading && (
        <GridMarginTop16 item xs={12}>
          <Skeleton />
        </GridMarginTop16>
      )}
      <div ref={ref}></div>
    </JournalContainer>
  );
});
