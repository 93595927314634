import { runInAction, makeAutoObservable, toJS } from "mobx";
import { uniqBy } from "lodash";
import { knsApi, sseUrl, vnsApi } from "lib/api";
import {
  deviceEmergencyCodeEnum,
  FacilityType,
  facilityTypeEnum,
} from "lib/enums";
import { Kns, Vns } from "lib/types";

type Filter = {
  organizationUnitId: number | null;
  type: FacilityType | null;
};
class Dispatcher {
  private eventSource: EventSource | null = null;
  private tryInitSse: number = 0;
  public knses: Kns[] = [];
  public vnses: Vns[] = [];
  public page: number = 1;
  public isLoading: boolean = false;
  // сортировка по отображению на карте, т.е показывать первыми те что на карте видны
  public isTracking: boolean = false;
  // текущие кнс/внс в области видимости на карте
  public trackingKns: Kns[] = [];
  public trackingVns: Vns[] = [];
  // отображать/скрывать список кнс/внс
  public showList: boolean = true;
  public active: Kns | Vns | null = null;
  public filter: Filter = {
    organizationUnitId: null,
    type: null,
  };

  constructor() {
    makeAutoObservable(this);
  }

  private findIndex = (deviceCode: number, type: FacilityType): number => {
    return type === facilityTypeEnum.kns
      ? this.knses.findIndex((kns: Kns) => kns.deviceCode === deviceCode)
      : this.vnses.findIndex((vns: Vns) => vns.deviceCode === deviceCode);
  };

  private hasError(arr: (Kns | Vns)[]) {
    return arr
      .filter((knsVns: Kns | Vns) => knsVns.onRepair !== true)
      .map((knsVns: Kns | Vns) => knsVns.emergencyCode)
      .flat()
      .some(
        (emergencyCode) =>
          emergencyCode !== deviceEmergencyCodeEnum.nonEmergency,
      );
  }

  initSse() {
    console.log("initSse", new Date().toLocaleTimeString());
    this.eventSource?.close();
    try {
      this.eventSource = new EventSource(sseUrl);
      this.eventSource.onmessage = (event) => {
        const data = JSON.parse(event.data);
        const idx = this.findIndex(data.deviceCode, data.type);
        if (idx !== -1) {
          switch (data.type) {
            case facilityTypeEnum.kns:
              this.knses[idx] = {
                ...this.knses[idx],
                ...data,
              };
              break;
            default:
              this.vnses[idx] = {
                ...this.vnses[idx],
                ...data,
              };
          }
        }
        if (this.active?.deviceCode === data.deviceCode) {
          this.active = {
            ...this.active,
            ...data,
          };
        }
      };
      this.eventSource.onerror = (e) => {
        console.error(`onError sse ${new Date().toLocaleTimeString()}`, e);
        this.tryInitSse += 1;
        if (this.tryInitSse > 5) {
          this.eventSource?.close();
          console.error(`Sse error`, e);
        } else {
          this.initSse();
        }
      };
    } catch (error) {
      console.log(`try catch ${new Date().toLocaleTimeString()}`);
    }
  }

  setKnses(knses: Kns[]) {
    this.knses = knses;
  }

  setVnses(vnses: Vns[]) {
    this.vnses = vnses;
  }

  async fetchData() {
    runInAction(() => {
      this.isLoading = true;
    });
    try {
      const { data: knses } = await knsApi.fetch({
        page: this.page,
        pageSise: undefined,
      });
      const { data: vnses } = await vnsApi.fetch({
        page: this.page,
        pageSise: undefined,
      });
      this.setKnses(knses);
      this.setVnses(vnses);
      if (this.active) {
        if (this.active.type === facilityTypeEnum.kns) {
          knses.forEach((kns: Kns) => {
            if (this.active?.id === kns.id) {
              runInAction(() => {
                this.active = kns;
              });
            }
          });
        } else {
          vnses.forEach((vns: Vns) => {
            if (this.active?.id === vns.id) {
              runInAction(() => {
                this.active = vns;
              });
            }
          });
        }
      }
    } catch (error) {
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  getList() {
    let res = [];
    switch (this.filter.type) {
      case facilityTypeEnum.kns:
        res = toJS([...this.trackingKns, ...this.knses]);
        break;
      case facilityTypeEnum.vns:
        res = toJS([...this.trackingVns, ...this.vnses]);
        break;
      default:
        res = toJS([
          ...this.trackingKns,
          ...this.trackingVns,
          ...this.knses,
          ...this.vnses,
        ]);
        break;
    }
    return uniqBy(
      res.filter((knsVns: Kns | Vns) => {
        return this.filter.organizationUnitId !== null
          ? knsVns.organizationUnitId === this.filter.organizationUnitId
          : true;
      }),
      "id",
    );
  }

  hasErrorKns(organizationUnitId?: number | null) {
    return this.hasError(
      organizationUnitId
        ? this.knses.filter((kns) =>
            organizationUnitId !== null
              ? kns.organizationUnitId === organizationUnitId
              : true,
          )
        : this.knses,
    );
  }

  hasErrorVns(organizationUnitId?: number | null) {
    return this.hasError(
      organizationUnitId
        ? this.vnses.filter((vns) =>
            organizationUnitId !== null
              ? vns.organizationUnitId === organizationUnitId
              : true,
          )
        : this.vnses,
    );
  }

  closeSse() {
    this.eventSource?.close();
  }
}

const dispatcher = new Dispatcher();

export default dispatcher;
